import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const Lily = props => {

  return (<Container>
    <Row>
      <Col>
        <h1>19 August 2020: Lily Protocol Update</h1>

        <p>Third remote update. Packet location traced to virtual private network. Packet location traced to United States.</p>

        <p className="protocol" id="lily-protocol">IZQa1Ld8ucYNM9cTwTQtycHTq2ExsbfKOkulkGbad7P2YskY8VkObiZ9Jp19Xn3XWjQQTyA7+bVwurgSM527xbxkpwuFPbrnlVWxpcjL1NV/5a2i0a0/6NzPaxpwAO3lmwkDz79LdZVy3FJQVdexXG5yRRGJeImiH1aZi1Yjgvhx/oULn/eCmM03eUBGW+mfhtdfgDC2cvdVkI1taxoxBjcoq4TYOOktToT7qgSvDY46XzRPGihv9s0PjMrPwuKBVyzbWsXfM01SE+58MZ5F/CN7S4kHc7usk4vYpd6YWvLh9HjTSl5pNgtw2CiRj1fesPM2K1LOeUzaJP5Dv34zht1tMQlFepVcPHgrKXCtLnoLZC5PTKwKeOVQFffA42wq/+t9PWgaiBw/QC9rGHazQifixdgyoQ8Va5wgpixLpS5c7jdQ3VPbHA9TTrUvRCzU0348s8PSPT++oM07eGXywlVQH44Bkr5wbvrNp4eRP/x+lkQgzRuR4uHe9uNDZnNoKLzOkINZ6Hc8ja4sS2WFoz3V7jQ+Tut54+ry9cEeBZ1LUR6+7lQnYwQBpKUHh4RkTx+ZdZfufdwqQkHYdQ9gUUkxOLtOn/wpa6QVW9Gq9BLRuGix8XOs8eD/52MMkZFEPKijqYi0kMep3pL14JIztxDo2iDcg/dIKqymnfSC6f3yKr64h6F9dRTLsu6ed0c+NiFq50UD0tbKbti/m0aFvUM9Jx7gm+lYghXR18nA6ZmuHCCLWTHBM9UxmZmsMLsP5Y9utcwGWtIogjXkNb29lIHnIxFfzb2FoAZbrwWsPvhQIyaFwt8We3U2SUpeSjS1rOSh4UV11+blg+9YAT1s7OQryQqkbqmZELx1ZgKqhouov71u0oKxnmwNeWNXi6w2y5y65Nw1ghvcb6lRqL0p0uO3zl9eBdPGmDvsUYzyhQkPF2KU2Aarc3JxvFeaeas9</p>
      </Col>
    </Row>
  </Container>);
};
