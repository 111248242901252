import React from "react";

import { theme, terms, email, social } from "../../utils";
import "./style.css";

import { Container, Row, Col } from "../Grid";
import { LinkInC, EmailC, LinkOutC } from "../Btn";
import { IconFAB } from "../Icon";

const Footer = () => {
  const date = new Date();

  return (
    <footer className={`page-footer center-align ${theme.shade} ${theme.text}`}>
      <Container>
        <Row>
          <Col size="s12 m4">
            <h2>About Us</h2>

            <p><LinkInC page={7} /></p>
          </Col>

          <Col size="s12 m4">
            <h2>Customer Service</h2>

            <p>Got a question or concern? Email us at <EmailC email={email.support} />.</p>

            <Row id="social-media">
              <Col>
                <LinkOutC link={social.discord}>
                  <IconFAB brand="discord" />
                </LinkOutC>
                
                <LinkOutC link={social.reddit}>
                  <IconFAB brand="reddit-alien" />
                </LinkOutC>
                
                <LinkOutC link={social.twitter}>
                  <IconFAB brand="twitter" />
                </LinkOutC>

                {/* <LinkOutC link={social.github}>
                  <IconFAB brand="github" />
                </LinkOutC>

                <LinkOutC link={social.telegram}>
                  <IconFAB brand="telegram-plane" />
                </LinkOutC> */}
              </Col>
            </Row>
          </Col>

          <Col size="s12 m4">
            <h2>Helpful Links</h2>

            <p><LinkInC page={6} /></p>
          </Col>
        </Row>
      </Container>

      <div className={`footer-copyright ${theme.base}`}>
        <p>
        <LinkInC page={2} /> | <LinkInC page={1} /> | <span className={`copyright ${theme.text}`}>&copy;{date.getFullYear()} {terms.company}</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;