import React from "react";

import { email, terms } from "./";

//  Staff members
export const staff = [
  {
    fullName: "Ryan Huang",
    position: "CEO",
    email: email.emperor,
    image: "profiles/ryan-huang.png",
    bio: <p>CEO and founder of {terms.company}.</p>,
    moon: {
      fullName: "Xuanyuan",
      position: "Yellow Emperor",
      bio: <p>Subordinate of Yudi. Ruler of the Center from Saturn. Oversees the change of seasons and Earth.</p>
    }
  },

  {
    fullName: "Drake Bloom",
    position: `Head of ${terms.division[0]}`,
    email: email.dragon,
    image: "profiles/drake-bloom.jpg",
    bio: <p>Head of human resources and public relations departments.</p>,
    moon: {
      fullName: "Taihao",
      position: "Azure Dragon",
      bio: <p>Subordinate of Huangdi. Ruler of the East from Jupiter. Oversees Spring and wood.</p>
    }
  },

  {
    fullName: "Heidi Norman",
    position: `Head of ${terms.division[1]}`,
    email: email.tortoise,
    image: "profiles/heidi-norman.jpg",
    bio: <p>Head of security, legal, maintenance, and accounting departments.</p>,
    moon: {
      fullName: "Zhuanxu",
      position: "Black Tortoise",
      bio: <p>Subordinate of Huangdi. Ruler of the North from Mercury. Oversees Winter and water.</p>
    }
  },

  {
    fullName: "William West",
    position: `Head of ${terms.division[2]}`,
    email: email.tiger,
    image: "profiles/william-west.jpg",
    bio: <p>Head of research, infirmary, and testing departments.</p>,
    moon: {
      fullName: "Shaohao",
      position: "White Tiger",
      bio: <p>Subordinate of Huangdi. Ruler of the West from Venus. Oversees Autumn and metal.</p>
    }
  },

  {
    fullName: "Camilla Robinson",
    position: `Head of ${terms.division[3]}`,
    email: email.bird,
    image: "profiles/camilla-robinson.jpg",
    bio: <p>Head of development, tech support, and customer support departments.</p>,
    moon: {
      fullName: "Shennong",
      position: "Vermillion Bird",
      bio: <p>Subordinate of Huangdi. Ruler of the South from Mars. Oversees Summer and fire.</p>
    }
  },

  {
    fullName: "Gregorio Shaw",
    position: `Head of ${terms.company} Infirmary`,
    email: email.clinic,
    image: "28MHLogo.png",
    bio: <p>Head of medical, counseling, and experimental services.</p>,
    moon: {
      position: `Head of ${terms.former} Infirmary`,
      bio: <p>Subordinate of Baidi. Unforgiven and unrepentant for his role in the April 4 Incident. Forbidden from leaving premises until he repents for his sins.</p>
    }
  },

  {
    fullName: "Lawrence Gee",
    position: "Head of Maintenance Department",
    email: email.maintenance,
    image: "28MHLogo.png",
    bio: <p>Head of maintenance services including mechanical repair, electrical wiring, plumbing, and general infrastructural sanitation.</p>,
    moon: {
      fullName: "Zhong Kui",
      position: "Head Exorcist",
      bio: <p>Subordinate of Yanluo. Charged with hunting, capturing, and managing ghosts. Contracted with Celestial Dragon Shrine.</p>
    }
  },

  {
    fullName: "Noah Crumpler",
    position: "Database Administrator",
    email: email.support,
    image: "28MHLogo.png",
    bio: <p>Head of tech support, archiving, and Tiger Head OS administrator.</p>,
    moon: {
      bio: <p>Spends working hours trying to hack the Tiger Head operating system.</p>
    }
  },

  {
    fullName: "Hannah Johnson",
    position: "Support Team Lead",
    email: email.support,
    image: "28MHLogo.png",
    bio: <p>Head of customer support team and employee support staff.</p>,
    moon: {
      bio: <p>Spends afterhours stealing beer from the supply warehouse and stashing it in Noah's desk.</p>
    }
  },

  {
    fullName: "Deborah Gee",
    position: "Contractor",
    email: email.contractor,
    image: "28MHLogo.png",
    bio: <p>Works with Maintenance Department for handling order requests.</p>,
    moon: {
      fullName: "Camael",
      position: "Lady Poppy",
      bio: <p>Subordinate of Chidi. Condemned to Earth for preemptively killing Sanxing during the Upheaval.</p>
    }
  }
];