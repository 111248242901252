import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const SixNovels = props => {
  return (<Container>
    <Row>
      <Col>
        <h1>The Six Classic Novel</h1>

        <p>The Classical Novels (古典小說) are a set of the six most well-known, studied, and adapted of pre-modern Chinese fiction. These books are <em>Romance of the Three Kingdoms</em>, <em>Water Margin</em>, <em>Journey to the West</em>, <em>The Golden Lotus</em>, <em>The Unofficial History of Scholars</em>, and <em>Dream of the Red Chamber</em>.</p>

        <p class="protocol" id="novel-two">zcaQpHKBnqGFwh3FyP01ZkQEufFUb0LSu3tctv+ExfsU11zFLkTomJXFbYMw1LglrJDZ6JqK7Pli4hujm9sc0t359IpXDbda0az3ub6Y/T5UNcSk+TDRkliotCJnLFM374IPynVzGwrFfxqGLr1BW0A+v7y9QkybcgvXFNivAz2CNqcW8WfNfyWA8/Nh6h9IHxWF9s4kScMcCkCvxwpYRiTbHxIib7Qzb//lS1Q0lXOiKuuRtfrwLkIiVt6wIrlPO4GUJI/Mps35ywnvMjD+smGt83wyxrlOC0Rn+SmkR23DLfokxcD8t1XQ4eRhfEytBTz4disLsnR57WOtyJq3SvBfdJ2bbPVworRrF9OknLveL41DW3CViccdNd1migRHqD1SALxFinlnEgcFqbBm+iRWGdM3awZUKVsxVsg7gPhZJPbXGxEDNwEyVF3cI9n/x2oMzQk/srz/ZBkrM/vEXbygtgNVZ0VhGRJrw/rBYSw3lfM7hNSypRLZRqO+g+b9th5psgWNJmgWDoHWIkExjXtD7uQoehObphoxxR6Rm0XDvobg96XiFXnw1U9Wsd8PgNS1j9tSppEMJ7Pfz4NIx8r9Lec3wI7Z8OECwTEY/o7jmDPLDV9ni4+pcS1Dwv2kO4+qxxuVkxc=</p>

        <p class="protocol" id="novel-six">i3cj4ovQ6LqOv4R+0BdoId+Le47auXmy7G7+XI7EPZN5ZkyHMThgJisI20/6VU565l2p9THVS3OeMDn5QRbBrauLM5c5XRI4r42bI92Nib2ip37lm6GjNigUMXGiQtD32ZuUYJgleU+tGt/IEOgBvRHgVmLM6Wz/HPQWR529iu2TzhqKx1eQG0DZ3w67Ap/IXmjN83MEtJTO98MisSw71i58NouhRJfHwUduImg35/VLa1LMZQE3YIcoNLz4ez2bRu9rBH0X9a7iYVhJoGlhGxg+u3wVh2/Wj8qVxKP6WyswCSejrdMW3APl+gTgKBoKHU5dq/bjE2RWO/zGIHrkzwVPC9ohElRlodbbVD3Nfljel/JNrmWzHOGZUtTtxWmpEny4Ug8618MNJCqpx5XEpOc8ajPohKU8Fmw/FlLmpvJXMA3SoXXLtbC6N5LtwqPL34APDviW8mklcu0hJnPORHufXfeq9Wu0SSrXRzkXyAWi5YdiFFdM9YO5oSOt9BCM9E7ET0tCxkIaycu87flQ6ZHsdCjQq6GTDk1X4nFRkBMcBdHmDAPG0Fg6qeb9qZsU6L09oF+pTBYHBhqI0QFlzCl5E13kGUA/n+zCCpm0WQqRZKW6OTuXScRQegLjzFmxE7T+IRmcC1APWqj8aoqa6FdXXJCBS+0CalyneQvhh6w/AfAnTGN9WKhqKUZPbEDwisHTNo6bgMMPB7aSJBiJ/DfQlAZOfIS7dFoQ7tbLkSYZ11sdT4XrD0hScr6Kd4y6HLJWTYn7FshX4K/gEBxz+6nMMT6xVYNcnd8OlPKGSIcWeiL4vViTU0s6jREWcWBMvq/BCgCWhiur/6ZneTf1OiyJyUYdQ5YZCPuvs2EHBTCVm70istxT+rF2BkF4Cn0ij40ru5/ltrhhi9fv//7VD+w9Gsq9QxPYNY6uwyhd7gzqDOdXhdQXqg==</p>
      </Col>
    </Row>

    <Row>
      <Col size="s12 m6 l4">
        <h2>Romance of the Three Kingdoms</h2>

        <p><em>Romance of the Three Kingdoms</em> (三國演義) is a historical novel attributed to Luo Guanzhong that combines history, legend, and myth in telling the story of the collapse of the Han Dynasty and the formation of the states of Cao Wei, Shu Han, and Eastern Wu. The plot and subplots revolve around the lives of the feudal lords and retainers involved in this tumultuous period. Due to the popularity of this novel, many of the personalities and events in the novel were thought to have happened in the actual historical record in popular belief.</p>

        <p class="protocol" id="novel-one">x4+AQWZ75kUtgean3SFY1Biw6NHOu10vhWY87Qv7iIZCjKEHz+7Bn+Ow2+0edLQcC4UluqTgvv9ESfZQ5BQagMLFaRwNnpfBrDMrYKGDy/TWfcG7I0D8Y3jFG7TvV+KEuGYQHDzftl1A1/SJfC957y/1tkR1eCzlm+sgPjdKYk2l4PReJbGYh2ixSbNA9jI84l0ATiPLPD91gAMIXJJ4eKbvB56suuzST9aTEtPyiL5Cy+b+Azs9kpRQujgwqmjJuHz9mEjTP8d6H/9LowrTu28mBkC80Ig4O7wFmMa9qc909HH/dXC7fS7awL+M4ngdiJyWhUdrlbAC8lgK7CUi79Faae+mjZApeY7eV7UCl0GHtZKxUdK1v73SKoNIMWn1lHFtY2mX6o6nLlucXLlQRAaahe2z9snqnFQI2loAquyHteP8fQpEcakTUv1k/23QKoe0M9kXwMU/B9MrZ1s/1lP7Uk7yCQ4Om7hRElbtYx7UbGYw1uy/Lftax/Vagz+LwN/Um7OU75zwZAkkYUD1r8Ggj6KVSm3agyKvCiVvpwDSYRB5s3eIssUv6KW+0GwGJG7+vx9MQOojh5nGCkzCog==</p>
      </Col>

      <Col size="s12 m6 l4">
        <h2>Water Margin</h2>

        <p>The novel <em>Water Margin</em> (水浒传), attributed to Shi Nai'an, tells the story of 108 outlaws who gather at Mount Liang during the Song Dynasty to rebel against the corrupt government. While they are eventually granted amnesty at the end, many of the outlaws meet tragic ends. These 108 outlaws were depicted to be the reincarnations of the repentant 108 Stars of Destiny who were originally banished by Shangdi.</p>
      </Col>

      <Col size="s12 m6 l4">
        <h2>Journey to the West</h2>

        <p>Attributed to Wu Cheng'en, <em>Journey to the West</em> (西遊記) tells a fictionalized version of the journey of the Tang dynasty monk Xuanzang. Included in this novel are elements of folk tales such as the inclusion of various legendary figures and those of the author's own creation during the monk Tang Sanzang's journey to India with his companions. Despite being a story about redemption, the most memorable character from this novel was Sun Wukong, known as the Monkey King.</p>

        <p class="protocol" id="novel-three">ZwGFjjWUjPuDBufHMaU+ACescXHzeuzckI/b9u1vfaMV8/VoPY1lc2zIEDUjnk+yutMAxYZVZvxfQoQUIR1FMtn4RPRor8f+T1/IYCdKGzMZ6lRohfL0LIZp27JDC4KUXVc/LP5eNyv/JALsAl02Tfs36bRLf32gj6aYGr1tBfgwLPBseHwyErVQgNhJ/iyJidO8qWO5+8vhmF+mMudYnx5bXsM5Tq+fZS6/BLGjl3uvnnuz9h+8xK/l8Dk8/W3okcPjGsVQZb5Bxba9e678CQ3QLxLDDLC/09dFgUg6Z3gVU1Qcfioka1s3R3AzYWak2FX2BIlxpki6oeLyzryhElQHtI1xJP7M3ctpV043iA961AlokoxTDWzJqzMHuteZmgp8Aib3uryC2stV+AuJcphPyFH+btZFbN9F28ysKhJpxTJJKp96uJxtwkjUCMPogS7F/orHIjMh9bapWrBzc58FemFeRzbjA752+pPYT5yRX+38NRnRpbUCuRvBzDpjtaPd1VfF1kQU8rELJdaNdVUdKKnrj6d2ZP+PK9KBWRilcamq419/cfQqWdTwKNteDrTysKB1L0hZNI+Ko+q/MPZMXq3KStfdlBPJOqphoavA23/MJa1cSHko1i7UbZfJE7KLmMhyu8knWPX8Qc6fdpz4huBbWipezlJ7sxLZSBO6E19TvZODhTLKE4DMAl7qr/4yp6s2GA/zsFZIU3pG35bAU7nH/XwyktcTV9de+VWcKrsLaW73L7gDQdtcXXwgevjiJy1BMIe6TAOLPxRbpC7OOwWfJt3j9NkNcHUpnCo=</p>
      </Col>

      <Col size="s12 m6 l4">
        <h2>The Golden Lotus</h2>

        <p>Framed as a spin-off to <em>Water Margin</em>, <em>The Golden Lotus</em> (金瓶梅) tells the story of the corrupt merchant Ximen Qing who takes the murderess Pan Jinlian as a wife and the subsequent domestic struggles in his household. The story as told by the Scoffing Scholar of Lanling ends with the comeuppance of most of the characters. Due to the nature of the narrative, the novel itself has been banned for most of its existence.</p>

        <p class="protocol" id="novel-four">SKcLC0g63449Od8EmupZZwH2LLcUCLWO2cY7xCNoQTNvDfXVeg7LP5AVtzqNfxju86kHHrEnNkmts5hDszsxL7ZOg+sFzjd89yEOPm2ktnCW04FdFHjkZwROAZK6znlKwfPc/UX8IOwUU3x+Uxaw9CmW1e2RcihzCl/W0JQNjZTzBnK/ZMPYZSMF3aWhzQrQcV2CIWT/s0HDU0UIQJEZPdyfSwzmKej8dgo9hzg0kLpnFSrZGB3ISUX2xYlav+sEtlZLMICZTv10OOqzUFAHACEdw7IbMnF9BO1QgfcjPxeMxQlUhr1cqOP4he31nLNvUXxD9WvCEztGXg6I5EV16csLiIPQCkueUbgmtO9Vv+D8bo/157DXODt2eWALC8MuYKZwaEGQf/EdM63s/WQmzEK4l/HIgR2/1Pe4bL56MgNJmdKvNEpzrPW48GjhhTgUULTG5II4XZ7xbcx5PufzzDikloeyjaCJUPb/UOhSv/EbI1LlTse17Esi5LipF70BChJfdNQkXyoIxclndI8HxHSl7NSWY8IZcX5/f2tVEwgbHcCHZgw3j8MwSjjpPoURXY4Y2NdrcEpb9hbF3hmO/VVK98zSt/ez1i/AqOXicX8+iJ9gYP6dag7w+ZYWTipf8kpGaiesqVmpgqlyRuubW+RB6W7ZhypWyC9lKZ47kCzxpUfTuAgzFXjD18UdD7R67j/1PJTw3+Axm9DbgyKdcR25HGB4VuDiiVM/0I0trOnzOLSQEGK7+HjE1457a3tGZnQVdlXjvKnvgs3rx2lXrWz8eHog61LZulT2bGsfMc4St1NCeaqQz/YMeSQiPRO5wbyvaInJa1dXP1gwevfvcQ==</p>
      </Col>

      <Col size="s12 m6 l4">
        <h2>The Unofficial History of the Scholars</h2>

        <p><em>The Unofficial History of the Scholars</em> (儒林外史), authored by Wu Jingzi, is a satirical novel consisting of loosely connected stories about various Confucian scholars. Most of the stories are didactic in nature which depict scholars ranging from obsessed, overtly rigid, hypocrtiical and/or corrupt as a way to critcize the underlying education system at the time. In spite of its structure, there may have been an autobiographical element to the novel.</p>

        <p class="protocol" id="novel-five">h/cZSF6fUd0QG5bmruNnHwX2GPXspfaEWxs2BybyU2RO1FvFvzxSkPKmDFfbXGmQ+piZy3NX4CHl2vBlW/bVN7c1ZmKTptSbuLYansPHNS/dOBGX6SvYSmiIYA12KFSg7hS+euObdPqMe+sKcfJ9p7cTxaZ3HcrSnYoeQfSHD1pcPAf4MNoSvbrCJQSVv/6EXkASFloKtaNBRGigsSJmZzDU66jZnE15vejAClHD5HSAFo/eyk8qhhfKLMb8H2sj0byj242/P9uUnFX4YvmBYF1YIW8PJ63u759JtFJGGTMHQC8TK5sHlNvvqqvvCl6sreXirRLJjTA6zxp9b4m3F/nfEK4femBeu6nBtmNHPdbsZY1tJMDO+XwMrX88udU71AMzk/OiKMqxx722D1xDINrT2FO8o+7e1mPlTn8wW+Vkd1KWsRm3Yihd0TeXFdYMzO35YGR9b5HX5YlZ1+YVgrquni/wrnorGv8j3YAEaMNm+NmTPeTxBDcYSt/fvItKd7T8U9iXaGAjhpv/0flmCd7+Y3CKConpFxS5asd8g4hk5Xoq1I0u7UahQ+yVmZEi8HXhotO6eVt0lbpjdCOUyqOOLV0sVcKN4lSPzBnppR3TUsRcR5pRlgxDTlohZ/4sS16skX6d0sJJXL+ym0FPbX7Eif2uerQrTjHRPC8r2VTtkoKhNQWBvoA7iI2LuLV2u91toyRoWW5ARgDoCGWHmp/GKlsCBcuO6tbJX+UbCabq4zMhVpFOtUfP+VXknrBccDS4u7D2ebOGEwAp40+1XEEfRkb+kn4pXerRbJkusr2yukkRBdcAST6fBtT50az0qNQfLdYzxbRv53opl0b9M2hCOrUCGGP3YaNo+MfWSBXnI1INL3ElLOLPuBcn+zE+u0ncjiaG55PPU6ZS0z/wHg==</p>
      </Col>

      <Col size="s12 m6 l4">
        <h2>Dream of the Red Chamber</h2>

        <p>The last classic novel, <em>Dream of the Red Chamber</em> (红楼梦) tells the story of the decline of a once wealthy family. Within the fall of this family is a story about the reincarnation of a stone and his subsequent relationship issues. The fates of the characters are foretold through the use of poetry and symbolism.</p>
      </Col>
    </Row>
  </Container>)
};
