import React from "react";
import { A } from "hookrouter";

import { terms, department, places, email, bot } from "../utils";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { LinkIn, Email } from "../components/Btn";

const Handbook = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />

          <StatusMsg>
            <strong>Last Updated: </strong>
            <em>January 10, 2019</em>
          </StatusMsg>

          <section id="handbook-intro">
            <p>Welcome to {terms.company}!</p>

            <p>As a member of {terms.company}, you are expected to review and follow the rules in this handbook.</p>

            <p>If you have any questions about the rules or require further clarification, email us at <Email email={email.support} />.</p>
          </section>

          <section id="handbook-facility">
            <h2>Facility Rules</h2>

            <ol>
              <li><strong>Be professional</strong>. You are in a corporate work environment and we expect you to be on your best behavior.

                <ul className="browser-default">
                  <li>We do not tolerate discriminatory or offensive behavior or speech from our employees.</li>

                  <li>Topics that are not appropriate for the workplace should be done in the {places.courtyard} or the {places.parking} .
                  </li>

                  <li>Any employee who sends content or discussion that can be considered Not Safe For Work (NSFW) outside of the {places.parking} will run the risk of being fired.</li>
                </ul>
              </li>

              <li>New employees ({department.intern}s) may choose their division once they complete their <LinkIn page={18} name="Onboarding">Onboarding Assessment</LinkIn>.</li>

              <li>Employees must be at least 18 years old to request a parking permit to the Parking Garage.
                <ul className="browser-default">
                  <li>Parking permits are given upon request from either a {department.manager}, {department.security}, or {department.hr}.</li>
                </ul>
              </li>

              <li>Be aware that the building is prone to intermittent infrastructure problems.
                <ul className="browser-default">
                  <li>Due to our security protocols, if there is an issue with the company Internet, any submissions will not be sent until the issue is resolved.</li>

                  <li>If you are aware of an infrastructure problem, contact the appropriate {department.maintenance} member to assist you.</li>
                </ul>
              </li>

              <li>Employees are encouraged to cordially fraternize with their co-workers and to participate in company events.
                <ul className="browser-default">
                  <li>For any disputes between employees, contact an {department.executive}, {department.manager}, or {department.security}.</li>

                  <li>If you have been {department.ghosted}, stop by the infirmary to get the help you need.</li>

                  <li>Employees are expected to show the appropriate respect during company events.</li>
                </ul>
              </li>

              <li>This is an at-will company and you can be fired if your behavior falls into the <A href="#handboook-termination">Employee Termination Protocol</A>.</li>

              <li>Former employees may reapply for their previous position at any time. Employment is dependent on their employee record and whether they are blacklisted from the facility.
                <ul className="browser-default">
                  <li><strong>Do not try to get around the blacklist.</strong></li>
                </ul>
              </li>

              <li>If you have any questions about the facility, feel free to ask the {department.info} (<code>{bot.info} &lt;question&gt;</code>) or directly message a member of Management ({department.executive}s, {department.manager}s, or {department.security}s).</li>

              <li>Employees are responsible for keeping up with company events. If an event has happened outside of their shift:
                <ul className="browser-default">
                  <li>Check the Bulletin Board</li>
                  <li>Ask the {department.info}: <code>{bot.info} &lt;question&gt;</code></li>
                  <li>Review the <LinkIn page={9} /></li>
                </ul>
              </li>
            </ol>
          </section>

          <section id="handbook-termination">
            <h3>Employee Termination Protocol</h3>

            <p>While we consider employees at {terms.company} to be family, there is a protocol for terminating members who prove to be too much of a liability for the company.</p>
          </section>

          <section id="handbook-warnings">
            <h4>Warnings</h4>

            <p>Employees are entitled to two warnings within a 7-day period before termination for the following behaviors:</p>

            <ul className="browser-default">
              <li>Displaying unprofessional behavior in the office. This includes, but is not limited to:
                <ul className="browser-default">
                  <li>Posting NSFW content in the {places.office} or {places.courtyard}.</li>

                  <li>Discussing off-topic matters outside of the {places.courtyard} or {places.parking}.</li>

                  <li>Uncivil behavior towards other employees.</li>
                </ul>
              </li>

              <li>Interrupting an employee during a critical task.</li>

              <li>Harassment of fellow employees.</li>

              <li>Inappropriate use of company resources:
                <ul className="browser-default">
                  <li>Employees that do not take critical tasks or meetings seriously will be considered liabilities to company resources.</li>

                  <li>Employees who consistently display an inability to review material they missed will be asked to review company resources before asking fellow employees about what happened.</li>
                </ul>
              </li>

              <li>Blatant disregard towards Management will result in a warning in addition to any other misbehavior(s).</li>
            </ul>
          </section>

          <section id="handbook-immediate-termination">
            <h4>Immediate Termination</h4>

            <p>Employees who display the following behaviors will be terminated immediately from the company and escorted off facility grounds:</p>

            <ul className="browser-default">
              <li>Spam is not tolerated in the facility and the perpetrator will be blacklisted.</li>

              <li>Corporate spies will be blacklisted immediately.</li>

              <li>Lack of response to changing an inappropriate name to a more appropriate one.</li>

              <li>Pornography is an unacceptable on company premises and will result in a place on the blacklist</li>

              <li>Impersonation of Management will result in the impersonator's immediate place in the blacklist.</li>
            </ul>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default Handbook;
