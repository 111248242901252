import React from "react";

import { terms, email } from "../utils";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { LinkIn, LinkOut, Email } from "../components/Btn";

const Disclaimer = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />
          
          <StatusMsg>
            <strong>Last Updated: </strong>
            <em>December 26, 2019</em>
          </StatusMsg>

          <section id="terms-intro">
            <p>Welcome to <strong>{terms.website}</strong> (the "Service" or the "Site"), the website to enter a new reality brought to you by {terms.company} ("us", "we", or "our"). If you would like to contact us about anything contained on the Site, contact us at <Email email={email.support} />.</p>

            <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Service. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>

            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
          </section>

          <section id="terms-site-access">
            <h2>Site Access</h2>

            <p>While we endeavor to ensure that the Site is normally available 24 hours a day, we shall not be liable if for any reason the Site is unavailable at any time or for any period.</p>
          </section>

          <section id="terms-disclaimer">
            <h2>Disclaimer</h2>

            <p>The Service is a component of the <strong>{terms.company} <LinkOut link="https://en.wikipedia.org/wiki/Alternate_reality_game">Alternate Reality Game (ARG)</LinkOut></strong> (the "Game").</p>

            <p>The scenario, events, and characters in the Game are a total work of fiction. Any resemblance to any factual events or actual people, living or dead, is coincidental.</p>

            <p>By joining the Discord server, you are agreeing to participate in the game and disclosing the personal information entailed in the <strong>Personal Information</strong> section. This personal information is used as outlined in the <LinkIn page={1} /></p>

            <p>Participation ("Employment") in the within the Game is voluntary and can be discontinued at either our or the user's discretion. Employment in the within the constraints and context of the game is not an offer of employment entitled to a salary or benefits.</p>
          </section>

          <section id="terms-account">
            <h2>Personal Information</h2>

            <p>Personal Information in the Game is the information used for Participation purposes.</p>

            <p>Full Participation in the Game requires a Discord account to access. The Discord account is authorized to use the Service through the <LinkIn page={4} />. Access to the Service can be confirmed by the {terms.bot} in the user's Authorized Apps for their Discord account.</p>
            
            <p>You are responsible for safeguarding your access to the Service and for any activities or actions under your Discord account. You must notify us immediately at <Email email={email.support} /> upon becoming aware of any breach in security or any unauthorized use of your Discord account.</p>

            <p>Optional Participation to the Game is allowed through voluntary disclosure of one (1) Reddit username and one (1) Twitter username per Discord account. The Service is not liable for any information that is further disclosed by the user.</p>
          </section>

          <section id="terms-termination">
            <h2>Termination</h2>

            <p>We may terminate or suspend your access to our Service immediately without prior notice or liability, for any reason whatsoever including:</p>

            <ul className="browser-default">
              <li>Breaching the Terms of this service</li>
              <li>Continued noncompliance to the Employee Handbook</li>
              <li>General disrespect and incivility to us and/or users and visitors of the Service and Game</li>
            </ul>

            <p>Upon termination, your right to use the Service will immediately cease. Termination of access may include cessation of access to the website and withdrawal from the Discord server.</p>

            <p>If you wish to terminate your access to the Service, you may leave the Discord server and withdraw authorization of the {terms.bot} from the Authorized Apps in your Discord account.</p>
          </section>

          <section id="terms-data-protection-privacy">
            <h2>Data Protection and Privacy</h2>

            <p>We will only use any Personal Information that we may collect about in accordance with our <LinkIn page={1} />. This policy forms an essential part of these Terms and Conditions and it is important that you read it. By accepting these Terms and Conditions, you also accept and consent to our Privacy Policy.</p>
          </section>

          <section id="terms-links-to-other-web-sites">
            <h2>Links to Other Web Sites</h2>

            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by {terms.company}.</p>

            <p>{terms.company} has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that {terms.company} shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such web sites or services.</p>

            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
          </section>

          <section id="terms-governing-law">
            <h2>Governing Law</h2>

            <p>This agreement shall be made under the laws of The United States of America and subject to the jurisdiction of the courts of The United States of America.</p>

            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
          </section>

          <section id="terms-changes">
            <h2>Changes</h2>

            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default Disclaimer;