import React, { useState, useEffect } from "react";

import API from "../utils/API";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { Btn, BtnA } from "../components/Btn";
import { RadioBtn } from "../components/Form";

const Exam = props => {
  const instructions = {
    notTaken: "You have not taken this assessment yet.",
    needToTake: "You are required to complete this assessment.",
    passed: "You have passed this assessment. You may retake it if you wish."
  };

  //  Exam data
  const [exam, setExam] = useState();
  const [examName, setExamName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState(0);
  const [correct, setCorrect] = useState(0);

  const [instruction, setInstruction] = useState(instructions.notTaken);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      //  Replaces underscores with spaces
      let examName = props.exam;

      if (examName.indexOf("_") !== -1) examName = examName.replace("_", " ");

      //  Finds the Exam
      API.getExam(examName)
        .then(exam => {
          setExam(exam.data._id);
          setExamName(exam.data.name);
          setQuestions(exam.data.questions);
          setTotal(exam.data.examLength);
          setCorrect(exam.data.correct);

          if (exam.data.passed.indexOf(props.uid) !== -1) {
            setInstruction(instructions.passed);
          } else if (exam.data.needed.indexOf(props.uid) !== -1) {
            setInstruction(instructions.notTaken);
          }

          setLoaded(true);
        })
        .catch(err => console.log(err));
    }
  }, [props, loaded, instructions]);

  //  Exam settings
  const qName = "q";
  const [answers, setAnswers] = useState([]);
  const [curr, setCurr] = useState(0);
  const [start, setStart] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [finish, setFinish] = useState(false);

  //  Randomizer function
  const randomize = (arr, same) => {
    let temp = arr[same];

    let index = same;
    do index = Math.floor(Math.random() * arr.length)
    while (index === same);

    arr[same] = arr[index];
    arr[index] = temp;
  };

  //  Randomizes the order of the questions
  const randomizeQuestions = () => {
    let qSet = questions;

    for (let i = 0; i < qSet.length; i++) {
      //  Randomizes the order of the choices
      for (let j = 0; j < qSet[i].choices.length; j++) {
        randomize(qSet[i].choices, j);
      }

      //  Switches the questions
      randomize(qSet, i);
    }
    
    setQuestions(qSet);
  };

  //  Updates the responses so far
  const responded = event => {
    let response = event.target.value;
    let arr = answers;

    ((curr + 1) < answers.length) ? arr.push(response) : arr[curr] = response;

    setAnswers(arr);
    setAnswered(true);
  };

  //  Moves to a different question
  const move = num => {
    if ((curr >= 0) && (curr < total)) {
      let choices = document.getElementsByName(`${qName}-${curr + 1}`);
      
      //  Clears the choices in a question
      for (let i = 0; i < choices.length; i++) {
        choices[i].checked = false;
      }

      setCurr(num);
      setAnswered(false);
    }
  };

  //  Gets the final results
  const [passed, setPassed] = useState(0);

  const status = {
    passed: `You have passed the ${examName} Assessment!`,
    failed: `You did not pass the ${examName} Assessment.`,
    waiting: `Waiting for the results of the ${examName} Assessment.`
  };
  const [resultsMsg, setResultsMsg] = useState(status.waiting);

  //  Evaluate the results
  const evaluate = () => {
    setStart(false);
    setFinish(true);

    let count = 0;

    for(let i = 0; i < questions.length; i++) {
      if (answers.indexOf(questions[i].answer) !== -1) count++;
    }

    setPassed(count);

    //  Updates the passed property if the User passes the Exam
    if (count >= correct) {
      API.userPassed(exam, props.uid)
        .then(() => setResultsMsg(status.passed))
        .catch(err => console.log(err));
    } else {
      setResultsMsg(status.failed);
    }
  };

  //  Starts the questions
  const begin = () => {
    setPassed(0);
    setResultsMsg(status.waiting);
    
    randomizeQuestions();
    setCurr(0);
    setFinish(false);
    setStart(true);
  };

  return (<>
  {(props.rank > 0)
  ? <Container>
    {(exam)
    ? <>
      <Row>
        <Col>
          <Title page={18}>{examName}</Title>
        </Col>
      </Row>

      {(start && !finish)
      ? <><Row>
        <Col styles="center-align">
          <h2>{curr + 1} out of {total}</h2>

          <StatusMsg>{questions[curr].text}</StatusMsg>
          
          {questions[curr].choices.map((choice, index) => <RadioBtn
            key={index}
            list={true}
            name={`${qName}-${curr + 1}`}
            value={choice}
            handleInputChange={e => responded(e)}
          >
            {choice}
          </RadioBtn>)}  
        </Col>
      </Row>
      
      <Row>
        <Col size="s6" styles="center-align">
          {(curr > 0) && 
          <Btn handleClickEvent={() => move(curr - 1)}>Back</Btn>}
        </Col>

        {answered && <Col size="s6" styles="center-align">
          {((curr + 1) === total)
          ? <Btn handleClickEvent={evaluate}>Finish</Btn>
          : <Btn handleClickEvent={() => move(curr + 1)}>Next</Btn>}
        </Col>}
      </Row></>
      : <Row>
        {(finish)
        ? <Col styles="center-align">
          <StatusMsg>You finished with {passed} out of {total}!</StatusMsg>

          <StatusMsg>{resultsMsg}</StatusMsg>

          {(passed < correct)
          ? <Btn handleClickEvent={begin}>
            Try Again?  
          </Btn>
          : <BtnA page={3}>
            Return to Dashboard  
          </BtnA>}
        </Col>
        : <Col styles="center-align">
          <StatusMsg>{instruction}</StatusMsg>

          <Btn handleClickEvent={begin}>
            Begin {examName} Assessment
          </Btn>
        </Col>}  
      </Row>}
    </>
    : <Row>
        <Col>
          <StatusMsg>This assessment is either unavailable or could not be found.</StatusMsg>
        </Col>
      </Row>}
    </Container>
  : <Container>
      <Row>
        <Col>
          <StatusMsg>Assessments are for employee use only.</StatusMsg>
        </Col>
      </Row>
    </Container>}
  </>);
};

export default Exam;