import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const Peony = props => {

  return (<Container>
    <Row>
      <Col>
        <h1>21 July 2020: Peony Protocol Update</h1>

        <p>Second remote update. Packet location traced to virtual private network.</p>

        <p className="protocol" id="peony-protocol">YrqCNk7BqHU5XT9MLrqF+ANUtjqfi3dljLdRY5CGE+cCLjtemps/Nu9oagb08jZ93XA0wowHxZlZJA5rfzU8DOahdzzB+Njl86YL0Yfm2hhYM+kU2wsgUYnsUg09/JeDUS3/p4mwjPfgejkz8Yoh01BexB5+fsPM4X6HZXwUDmD8gM5WgblgoGTi6PSKHVxThjHf8KVcVUJ0u5SV/27zknGOcq2/fGWJmjER3wQQOvclfrrvTrQzrX8iM0K/V9SASJkt7lTC/TwHuzwZQMm1/8PAALAfSC5WsoJstLrrM++qdNj1E6am9phoeGZoJiq+es7tc/iCWnRns0gfKQIVXf4UoFEFylD1HjgV9JdbNPFFban7ZDV7hPkA8YVjMsXan6/wyV1nAEDDF66HGPybK198to1/v2fVgmaNBymNKCk7lkCbQzDDuFnFgXi3JmXA5mYBthoj4V85HzHjnxqokJplt52qL+a3bcnKdPBb9bW90Fv05KPif2MwM37Y4RlpB8qA3c61f8pgqetrjYoFQnGFfijtF4+n0oXbLL4MYWNRPTyMa0Knsh8dSiXw7gdkpJBKhbGx+SR8cz5SoNUesenqtWnLROjM42Tst56bxQFQkM0+QZHoyNnsdj2mTM49xYlhPaQO3b1oeUsCYxf/RqnMwuE3FNuv7zZ9faLf5lA9DRZDnDlC7ta/7rhuLlQ/nH08L7xCMGbBtl1BpX3BZtnejSVVEv5d</p>
      </Col>
    </Row>
  </Container>);
};
