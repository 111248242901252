import React, { useState, useEffect } from "react";

import API from "../utils/API";

import { Container, Row, Col } from "../components/Grid";
import { StatusMsg, Title } from "../components/Msg";
import { } from "../components/Form";
import { EditInput } from "../components/Editable";

const Ranker = props => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (roles.length === 0) {
      API.getRoles()
        .then(dbRoles => {
          setRoles(dbRoles.data
            .filter(role => role.name !== "@everyone")
            .sort((a, b) => b.rank - a.rank));
        })
        .catch(err => console.log(err));
    }
  }, [roles]);

  return (
    <Container>
    {(props.rank === 3)
    ? <><Row>
      <Col>
        <Title page={props.page} />
      </Col>
    </Row>
    
    <Row>
      <Col>
      {(roles.length > 0)
      ? <table className="highlight centered responsive-table">
        <thead>
          <tr>
            <th>Role Name</th>
            <th>Rank</th>
          </tr>
        </thead>

        <tbody>
        {roles.map((role, index) => <tr key={index}>
          <td>{role.name}</td>
          <td>
            <EditInput
              id={role.id}
              attr="rank"
              handleUpdate={API.updateRole}
              type="number"
              min="1"
              max="3"
            >
              {role.rank}
            </EditInput>
          </td>
        </tr>
        )}
        </tbody>
      </table>
      : <StatusMsg>No roles could be loaded.</StatusMsg>}
      </Col>
    </Row></>
    : <Row>
      <Col>
        <StatusMsg>You do not have access to this page.</StatusMsg>
      </Col>
    </Row>
    }
    </Container>
  );
};

export default Ranker;