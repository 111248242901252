import React, { useState } from "react";

import { Icon } from "../Icon";
import { Input } from "../Form";

export const EditInput = props => {
  const [edit, setEdit] = useState(false);
  const [currValue, setCurrValue] = useState(props.children);
  const [updatedValue, setUpdatedValue] = useState(props.children);

  //  Update the value for the EditInput
  const updateValue = () => {
    props.handleUpdate(props.id, {
      [props.attr]: updatedValue
    });

    setCurrValue(updatedValue);
    setEdit(false);
  };

  return (
    <div className="editable">
      {edit
      ? <Input 
        size={props.size || "s12"}
        autocomplete={props.autocomplete}
        id={`update-${props.attr}`}
        type={props.type}
        disabled={props.disabled}
        placeholder={props.children}
        min={props.min}
        max={props.max}
        handleInputChange={e => setUpdatedValue(e.target.value)}
        label={props.label}
        colors={props.colors} />
      : currValue}

      <div className="right">
      {edit
      ? <>
        <Icon handleClick={updateValue}>check</Icon>
        <Icon handleClick={() => setEdit(false)}>cancel</Icon>
      </>
      : <Icon handleClick={() => setEdit(true)}>edit</Icon>}
      </div>
    </div>
  );
};