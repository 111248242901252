import React, { useState, useEffect, useCallback } from "react";

import { paths } from "./";
import API from "../utils/API";

import { Container, Row, Col } from "../components/Grid";
import { StatusMsg } from "../components/Msg";

const Logout = props => {
  const [loggedOut, setLoggedOut] = useState(false);
  const [rank, setRank] = useState(props.rank);

  //  Redirects to the Home page if logged out
  const redirectHome = useCallback(() => {
    setTimeout(() => {
      window.location = paths[0].path;
    }, 3000);
  }, []);

  //  Logs out the user by deleting the session
  const logOut = useCallback(async () => {
    await API.logout();
    setRank(0);
    setLoggedOut(true);
  }, []);
  
  useEffect(() => {
    if (rank !== 0) {
      logOut();
    }
     
    redirectHome();
  }, [rank, logOut, redirectHome]);

  //  Upon successful logout, return the user to the Home page
  return (
    <Container>
      <Row>
        <Col>
          <StatusMsg>
          {(loggedOut)
          ? "You have been logged out"
          : "Logging you out..."}
          </StatusMsg>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;