import React from "react";

import { archived } from "../utils";

//  Pages
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import Disclaimer from "./Disclaimer";
import Dashboard from "./Dashboard";
import Logout from "./Logout";
import ApplyToday from "./ApplyToday";
import Team from "./Team";
import Archiver from "./Archiver";
import Archives from "./Archives";
import Archive from "./Archive";
import Exam from "./Exam";
import Division from "./Division";
import Handbook from "./Handbook";
import Ranker from "./Ranker";
import { Orchid } from "./Orchid/Orchid";
import { FourGentlemen } from "./Orchid/FourGentlemen";
import { Peony } from "./Peony/Peony";
import { SixNovels } from "./Peony/SixClassicNovels";
import { Lily } from "./Lily/Lily";
import { Osmanthus } from "./Osmanthus/Osmanthus";
import { Chrysanthemum } from "./Chrysanthemum/Chrysanthemum";
import { Lunhui } from "./Chrysanthemum/Lunhui";

export const paths = [
  //  [0] Home page
  {
    path: "/",
    title: "Home"
  },

  //  [1] Privacy Policy
  {
    path: "/privacy-policy",
    title: "Privacy Policy"
  },

  //  [2] Disclaimer
  {
    path: "/terms-conditions",
    title: "Terms and Conditions"
  },

  //  [3] Dashboard
  {
    path: "/dashboard",
    title: "Dashboard"
  },

  //  [4] Login
  {
    path: "/login",
    title: "Employee Portal"
  },

  //  [5] Logout
  {
    path: "/logout",
    title: "Log Out"
  },

  //  [6] Apply Today
  {
    path: "/apply-today",
    title: "Apply Today!"
  },

  //  [7] Meet Our Team
  {
    path: "/team",
    title: "Meet Our Team"
  },

  //  [8] Archive Generator
  {
    path: "/archiver",
    title: "Archive Generator"
  },

  //  [9] Archives
  {
    path: "/archives",
    title: "Archives"
  },

  //  [10] Logs
  {
    path: `/archives/${archived[0]}`,
    title: "Logs Archive"
  },

  //  [11] Log
  {
    path: `/archives/${archived[0]}/:num`,
    title: "Log"
  },

  //  [12] Memos
  {
    path: `/archives/${archived[1]}`,
    title: "Memos Archive"
  },

  //  [13] Memo
  {
    path: `/archives/${archived[1]}/:num`,
    title: "Memo"
  },

  //  [14] Recordings
  {
    path: `/archives/${archived[2]}`,
    title: "Recordings Archive"
  },

  //  [15] Recording
  {
    path: `/archives/${archived[2]}/:num`,
    title: "Recording"
  },

  //  [16] Transmissions
  {
    path: `/archives/${archived[3]}`,
    title: "Transmissions Archive"
  },

  //  [17] Transmission
  {
    path: `/archives/${archived[3]}/:num`,
    title: "Transmission"
  },

  //  [18] Assessment
  {
    path: `/assessment/:exam`,
    title: "Assessment"
  },

  //  [19] Division
  {
    path: `/divisions`,
    title: "Divisions"
  },

  //  [20] Employee Handbook
  {
    path: `/handbook`,
    title: "Employee Handbook"
  },

  //  [21] Rank Adjuster
  {
    path: `/ranker`,
    title: "Rank Adjuster"
  },

  //  [22] Orchid Protocol
  {
    path: `/orchid`,
    title: "Orchid Protocol"
  },

  //  [23] Orchid Protocol: Four Gentlemen
  {
    path: `/orchid/four-gentlemen`,
    title: "Orchid Protocol: Four Gentlemen"
  },

  //  [24] Peony Protocol
  {
    path: `/peony`,
    title: "Peony Protocol"
  },

  //  [25] Six Classic Novels
  {
    path: `/peony/six-classic-novels`,
    title: "Peony Protocol: Six Classic Novels"
  },

  //  [26] Lily Protocol
  {
    path: `/lily`,
    title: "Lily Protocol"
  },

  //  [27] Osmanthus Protocol
  {
    path: `/osmanthus`,
    title: "Osmanthus Protocol"
  },

  //  [28] Chrysanthemum Protocol
  {
    path: `/chrysanthemum`,
    title: "Chrysanthemum Protocol"
  },

  //  [29] Lunhui
  {
    path: `/lunhui`,
    title: "Lunhui"
  }
];

export const pages = (rank, name, uid) => {
  return {
    [paths[0].path]:
      () => <Home key={0} page={0} rank={rank} />,
    [paths[1].path]:
      () => <PrivacyPolicy key={1} page={1} />,
    [paths[2].path]:
      () => <Disclaimer key={2} page={2} />,
    [paths[3].path]:
      () => <Dashboard key={3} page={3} rank={rank} name={name} uid={uid} />,
    [paths[5].path]:
      () => <Logout key={5} page={5} rank={rank} />,
    [paths[6].path]:
      () => <ApplyToday key={6} page={6} />,
    [paths[7].path]:
      () => <Team key={7} page={7} rank={rank} />,
    [paths[8].path]:
      () => <Archiver key={8} page={8} rank={rank} />,
    [paths[9].path]:
      () => <Archives key={9} page={9} rank={rank} />,
    [paths[10].path]:
      () => <Archive key={10} page={10} logType={archived[0]} rank={rank} />,
    [paths[11].path]:
      ({num}) => <Archive key={11} page={11} logType={archived[0]} num={num} rank={rank} />,
    [paths[12].path]:
      () => <Archive key={12} page={12} logType={archived[1]} rank={rank} />,
    [paths[13].path]:
      ({num}) => <Archive key={13} page={13} logType={archived[1]} num={num} rank={rank} />,
    [paths[14].path]:
      () => <Archive key={14} page={14} logType={archived[2]} rank={rank} />,
    [paths[15].path]:
      ({num}) => <Archive key={15} page={15} logType={archived[2]} num={num} rank={rank} />,
    [paths[16].path]:
      () => <Archive key={16} page={16} logType={archived[3]} rank={rank} />,
    [paths[17].path]:
      ({num}) => <Archive key={17} page={17} logType={archived[3]} num={num} rank={rank} />,
    [paths[18].path]:
      ({exam}) => <Exam key={18} page={18} exam={exam} rank={rank} uid={uid} />,
    [paths[19].path]:
      () => <Division key={19} page={19} uid={uid} />,
    [paths[20].path]:
      () => <Handbook key={20} page={20} uid={uid} />,
    [paths[21].path]:
      () => <Ranker key={21} page={21} rank={rank} />,
    [paths[22].path]:
      () => <Orchid key={22} page={22} />,
    [paths[23].path]:
      () => <FourGentlemen key={23} page={23} />,
    [paths[24].path]:
      () => <Peony key={24} page={24} />,
    [paths[25].path]:
      () => <SixNovels key={25} page={25} />,
    [paths[26].path]:
      () => <Lily key={26} page={26} />,
    [paths[27].path]:
      () => <Osmanthus key={27} page={27} />,
    [paths[28].path]:
      () => <Chrysanthemum key={28} page={28} />,
    [paths[29].path]:
      () => <Lunhui key={29} page={29} />
  };
};
