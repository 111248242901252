import React from "react";

import { terms, email, theme, archived } from "../utils";
import { staff } from "../utils/staff";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { LinkIn, LinkInC, Email } from "../components/Btn";
import { CardPanel } from "../components/Card";

const Archives = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />
        </Col>
      </Row>

    {(props.rank > 0)
      ?<><Row>
        <Col size={(props.rank > 1) ? "s12 m7" : "s12"}>
          <p>Here at {terms.company}, we understand that things move fast in the company, so our archivist {staff[7].fullName} has compiled the most relevant company correspondences for employee use. If you are having issues with accessing the archives, let a support team member know at <Email email={email.support} />.</p>
        </Col>

        {(props.rank > 1) && <Col size="s12 m5" styles={theme.warning}>
          <p>In addition to {archived[0]}s and {archived[1]}s, certain employees may be privy to other company correspondences. Management requests that these be kept out of confidential from other employees.</p>
        </Col>}
      </Row>
      
      <Row>
        <CardPanel size="m6">
          <p className="center-align"><LinkInC page={10} /></p>
        </CardPanel>

        <CardPanel size="m6">
          <p className="center-align"><LinkInC page={12} /></p>
        </CardPanel>
      </Row>
      
      {(props.rank > 1) && <Row>
        <CardPanel size="m6">
          <p className="center-align"><LinkInC page={14} /></p>
        </CardPanel>

        <CardPanel size="m6">
          <p className="center-align"><LinkInC page={16} /></p>
        </CardPanel>
      </Row>}
      </>
      : <Row>
        <Col>
          <StatusMsg>Only {terms.company} employees have access to the company archives.</StatusMsg>

          <StatusMsg>Go to the <LinkIn page={4} /> and sign in with your credentials to access the archives.</StatusMsg>
        </Col>
      </Row>
    }
    </Container>
  );
};

export default Archives;