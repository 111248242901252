import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const Chrysanthemum = props => {
  return (<Container>
    <Row>
      <Col>
        <h1>17 October 2020: Chrysanthemum Protocol Update</h1>

        <p>Fifth remote update. Packet location traced to private virtual network. Packet location traced to Oakland, California, United States.</p>

        <hr />

        <p>
          <strong>User</strong>: Louis Ying<br />
          <strong>Company</strong>: Astra BA Research
        </p>

        <p class="protocol" id="chrysanthemum-protocol">ThPUaQ02dEB5zJ6HgwAgbCXmcnhEWOi1OyHp86JCJhA4AR2f5aNZjzDbpaWh4J/iAXFH+Q/mz+HtJ787vmDyuZuTQOGctMOQPF6adYTH4Od86cOMm5ZW+1sDXPwC+f0ZQV//58y0UfesHneagUh4as6fE5MvEYsdmmmwUFIuiayBBKZIYOVJlg4nInyzUhg5cV82PBgK9OdigoO2APEC/NHO4suyQkMdpFLvBuPAfqB0ExSJ4v/mYelhGz1Pm0Hj7PoOwuq7v6sKyjc+Wc8EBAuLuewW7AoK5k8OpE+poEsR9LLH3ox7B/R5u/Y2Wu/AxfI8bfQDlTWptQKhg3yvLkn7Vt5B4euJ5WKVk5Q2xjVN53vw/8PVHhCAuNJQVdeSGwhKHnGvmCJaaxpZO62nGRZhYJPlP8EGI6E1bDlorBH5jwMv+r6WhlDV5tjwMRtYvU9xG/eSjhRDjABV3DxeqXvYLEPYPYnVPa4AXo+ErgYdWlT8bMyU/JVsXMLCt4VdhVcswbc/i83x1SlngN3yp/LRY3uEeN2dEIkjZ5z7owDy9s6qYerHkS3b3BdlRPlV0e2YEMCdZ+jOqN+rKjNdr4caRe3sjfYhLoJ5JTgmW7W7aO7ASHm+5LrVBjndRZ2oKZiowLT5wvy15fHlzzjvOWRuLSxhrtV9RcLOUOAakKk=</p>
      </Col>
    </Row>
  </Container>)
};
