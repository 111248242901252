import React from "react";
import { A } from "hookrouter";

import { theme } from "../../utils";
import { paths } from "../../pages";

export const Btn = props => {
  return (
    <button
      onClick={props.handleClickEvent}
      className={`btn ${(props.flat) ? "btn-flat" : "waves-effect waves-light"} ${(props.round) ? "btn-floating" : ""} ${props.color || theme.base} ${props.colorText || theme.text}${(props.size) ? ` btn-${props.size}` : ``}`}>
      {props.children}
    </button>
  );
};

export const BtnA = props => {
  let path = paths[props.page].path;
  let title = paths[props.page].title;

  if (props.num) {
    path = path.substring(0, path.lastIndexOf(":")) + props.num;
    title += ` ${props.num}${(props.children) ? `: ${props.children}` : ""}`;
  } else if (props.name) {
    path = path.substring(0, path.lastIndexOf(":")) + props.name;
  }

  return (
    <a 
      href={props.link || path}
      target={(props.target) ? "_blank" : "_self"}
      rel="noopener noreferrer"
      className={`btn ${(props.flat) ? "btn-flat" : `waves-effect waves-${props.waves || "light"}`} ${(props.round) ? "btn-floating" : ""} ${props.color || theme.base} ${props.colorText || theme.text}${(props.size) ? ` btn-${props.size}` : ``}`}>
        {props.children || title}
    </a>
  );
};

//  Link for using the paths in the page index
export const LinkIn = props => {
  let path = paths[props.page].path;
  let title = paths[props.page].title;

  if (props.num) {
    path = path.substring(0, path.lastIndexOf(":")) + props.num;
    title += ` ${props.num}${(props.children) ? `: ${props.children}` : ""}`;
  } else if (props.name) {
    path = path.substring(0, path.lastIndexOf(":")) + props.name;
  }

  return (
    <A href={path} className={`${props.color || theme.link} ${props.className || ""}`}>{(props.num) ? title : (props.children || title)}</A>
  );
};

export const LinkInC = props => {
  let path = paths[props.page].path;
  let title = paths[props.page].title;

  if (props.num) {
    path = path.substring(0, path.lastIndexOf(":")) + props.num;
    title += ` ${props.num}${(props.children) ? `: ${props.children}` : ""}`;
  } else if (props.name) {
    path = path.substring(0, path.lastIndexOf(":")) + props.name;
  }

  return (
    <A href={path} className={`${props.color || theme.text} ${props.className || ""}`}>{(props.num) ? title : (props.children || title)}</A>
  );
};

//  Link for sending emails
export const Email = props => {
  return (
    <a href={`mailto:${props.email}`} className={`${props.color || theme.link}`}>{props.children || props.email}</a>
  );
};

export const EmailC = props => {
  return (
    <a href={`mailto:${props.email}`} className={`${props.color || theme.text}`}>{props.children || props.email}</a>
  );
};

//  Link for other links
export const LinkOut = props => {
  return (
    <a href={props.link} target={props.target} rel="noopener noreferrer" className={`${props.color || theme.link}`}>{props.children || props.link}</a>
  );
};

export const LinkOutC = props => {
  return (
    <a href={props.link} target={props.target} rel="noopener noreferrer" className={`${props.color || theme.text}`}>{props.children || props.link}</a>
  );
};