import React, { useState, useEffect } from "react";

import { email, terms, theme } from "../utils";
import { paths } from "./";
import API from "../utils/API";

import { Container, Row, Col } from "../components/Grid";
import { Email, LinkIn } from "../components/Btn";
import { Title, StatusMsg } from "../components/Msg";

const Dashboard = props => {
  const msg = {
    loading: "Loading dashboard...",
    notLoggedIn: "You are not logged in."
  };

  const [userLoaded, setUserLoaded] = useState(false);
  const [discordId, setDiscordId] = useState();
  const [username, setUsername] = useState();
  const [nickname, setNickname] = useState();
  const [avatar, setAvatar] = useState();
  const [roles, setRoles] = useState([]);
  const [examNeed, setExamNeed] = useState([]);
  // const [examPass, setExamPass] = useState([]);
  const [division, setDivision] = useState(5);
  const [choose, setChoose] = useState(false);

  //  Redirects to the login if the User is not logged in.
  if (props.rank === 0) {
    API.login()
      .then(res => {
        window.location = res.data;
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    if (!userLoaded && props.uid) {
      //  Get basic User information
      API.getUser(props.uid)
        .then(user => {
          setDiscordId(user.data.discordId);
          setAvatar(user.data.avatar);
          setUsername(user.data.username);
          setNickname(user.data.display);

          //  Deals with roles
          const roleList = user.data.roles
            .filter(role => role.role !== "@everyone")
            .sort((a, b) => (a.rank > b.rank) ? -1 : 1)
            .map(role => role.role);

          setRoles(roleList);

          //  Get Division information
          for (let i = 0; i < terms.division.length; i++) {
            if (roleList.indexOf(terms.division[i]) !== -1) {
              setDivision(i);
              break;
            }
          }

          //  Gets Exams that User has passed
          API.getExamPass(props.uid)
          .then(exams => {
            // setExamPass(exams.data.map(exam => exam.name));
            const examList = exams.data.map(exam => exam.name);
            
            //  Once an Intern passes Onboarding, the choice to select a Division is available
            if ((examList.indexOf("Onboarding") !== -1) && (roleList.indexOf("Intern") !== -1)) {
              setChoose(true);
            }
          })
          .catch(err => console.log(err));

        })
        .catch(err => console.log(err));

      //  Get Exams that User needs to take
      API.getExamNeed(props.uid)
        .then(exams => setExamNeed(exams.data.map(exam => exam.name)))
        .catch(err => console.log(err));

      setUserLoaded(true);
    }
  }, [props.uid, userLoaded]);

  return (
  <>
  {(props.rank > 0)
    ? <Container>
      <Row>
        <Col>
          <Title page={props.page}>{(props.name)
          ? `Welcome ${props.name}!`
          : paths[props.page].title }</Title>
        </Col>
      </Row>

      <Row>
        <Col size="s12 m6" styles={theme.division[division]}>
        {(userLoaded)
        ? <>{choose &&<StatusMsg>You may now choose a <LinkIn page={19}>division</LinkIn> to join.</StatusMsg>}
        
        <StatusMsg>{terms.division[division]}</StatusMsg>

        <p>
          <img src={`https://cdn.discordapp.com/avatars/${discordId}/${avatar}`} alt={`${props.name}'s avatar`} />
          <br />
          <span><strong>Name</strong>: {props.name}</span>
          <br />
          <span><strong>Employee Name</strong>: {nickname}</span>
          <br />
          <span><strong>Discord Account</strong>: {username}</span>
          <br />
          <span><strong>Role(s)</strong>: {roles.join(", ")}</span>
        </p></>
        : <StatusMsg>{msg.loading}</StatusMsg>}
        </Col>

        <Col size="s12 m6" styles="center-align">
          <h2>Required Assessments</h2>

          {(examNeed.length > 0)
          ? <ul>
            {examNeed.map((exam, index) => <li key={`exam-${index}`}>
              <LinkIn page={18} name={exam}>{exam}</LinkIn>
            </li>)}
          </ul>
          : <StatusMsg>You are currently not required to take any assessments at this time.</StatusMsg>}
        </Col>
      </Row>

      <Row>
        <Col>
          <h2>Further information</h2>

          {(terms.division[division] === "Intern")
          ? <p>Please review the <LinkIn page={20} /> before taking the Onboarding Assessment.</p>
          : <p>Feel free to review the <LinkIn page={20} />.</p>}

          <p>If you have any technical issues on the website either talk to Tech Support on the Discord server or email Tech Support at <Email email={email.support} />.</p>
        </Col>
      </Row>
    </Container>
    : <Container>
    <Row>
      <Col>
        <Title page={props.page} />

        {(props.rank === 0)
        ? <StatusMsg>{msg.notLoggedIn}</StatusMsg>
        : <StatusMsg>{msg.loading}</StatusMsg>}
      </Col>  
    </Row>
  </Container>
  }
  </>
  );
};

export default Dashboard;