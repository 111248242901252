import React from "react";

import { theme } from "../../utils";
import { staff } from "../../utils/staff";

import { Col } from "../Grid";
import { EmailC } from "../Btn";
import { Icon } from "../Icon";

export const Card = props => {
  return (
    <Col size={props.size || "s12 m4"}>
      <div className={`card ${props.color || theme.shade}`}>
        <div className={`card-content ${props.text || theme.text}`}>
          <span className="card-title">{props.title}</span>

          {props.children}
        </div>
      </div>
    </Col>
  );
};

export const ImageCard = props => {
  return (
    <Col size={props.size || "s12 m4"}>
      <div className={`card ${props.color || theme.shade}`}>
        <div className={`card-image`}>
          <img src={props.image} alt={props.alt} />

          <span className="card-title">{props.title}</span>
        </div>

        <div className={`card-content ${props.text || theme.text}`}>
          {props.children}
        </div>
      </div>
    </Col>
  );
};

export const HorizontalCard = props => {
  return (
    <Col size={props.size || "s12"}>
      <div className={`card horizontal ${props.color || theme.shade}`}>
        <div className={`card-image`}>
          <img src={props.image} alt={props.alt} />
        </div>

        <div className={`card-stacked ${props.text || theme.text}`}>
          <div className="card-content">
            <span className="card-title">{props.title}</span>

            {props.children}
          </div>
        </div>
      </div>
    </Col>
  );
};

export const RevealCard = props => {
  return (
    <Col size={`${props.size || "s12 m6"}`}>
      <div className={`card ${props.color || theme.shade}`}>
        <div className={`card-image waves-effect waves-block waves-light`}>
          <img className="activator" src={props.image} alt={props.alt} />
        </div>

        <div className={`card-content ${props.text || theme.text}`}>
          <span className="card-title activator">
            {props.title}
            <Icon size="right">arrow_drop_down</Icon>
          </span>

          <p>{props.preview}</p>
        </div>

        <div className={`card-reveal ${props.reveal || theme.base} ${props.text || theme.text}`}>
          <span className="card-title">
            {props.title}
            <Icon size="right">close</Icon>
          </span>

          {props.children}
        </div>
      </div>
    </Col>
  );
};

export const CardPanel = props => {
  return (
    <Col size={`s12 ${props.size || "m6 l4"}`}>
      <div className={`card-panel ${props.color || theme.shade} ${props.text || theme.text}`}>
        {props.children}
      </div>
    </Col>
  );
};

export const ProfileCard = props => {
  return (
    <Col size={`s12 ${props.size || "m6 l4"}`}>
      <div className={`card ${props.color || theme.shade} profile`}>
        <div className={`card-image waves-effect waves-block waves-light`}>
          <img className={(props.rank > 0) && "activator"} 
          src={`/images/${props.image || staff[props.member].image}`} 
          alt={props.name || staff[props.member].fullName} />
        </div>

        <div className={`card-content ${props.text || theme.text}`}>
        
        {(props.rank > 0)
         ? <span className="card-title activator">
            {props.name || staff[props.member].fullName}
            <Icon size="right">arrow_drop_down</Icon>
          </span>
          : <span className="card-title">
            {props.name || staff[props.member].fullName}
          </span>}

          <p><em>{props.position || staff[props.member].position}</em></p>
        </div>

      {(props.rank > 0) && <><div className={`card-reveal ${props.color || theme.base} ${props.text || theme.text}`}>
          <span className="card-title">
            {props.name || staff[props.member].fullName}
            <Icon size="right">close</Icon>
          </span>

          {props.children || staff[props.member].bio}
        </div>

        <div className={`card-action ${props.link || theme.text}`}>
          <EmailC email={staff[props.member].email || props.email}>
            <Icon size="small">mail</Icon>
          </EmailC>
        </div></>}
      </div>
    </Col>
  );
};