import React from "react";

export const Container = props => {
  return (
    <div className={`container ${props.styles || ""}`} id={props.id}>
      {props.children}
    </div>
  );
};

export const Row = props => {
  return (
    <div className={`row ${props.styles || ""}`} id={props.id}>
      {props.children}
    </div>
  );
};

export const Col = props => {
  return (
    <div className={`col ${props.size || "s12"} ${props.styles || ""}`} id={props.id}>
      {props.children}
    </div>
  );
};
