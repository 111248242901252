import React from "react";

import { paths } from "../../pages";
import { terms } from "../../utils";
import "./style.css";

export const StatusMsg = props => {
  return (
    <p className="msg-status">{props.children}</p>
  );
};

export const Title = props => {
  let title = `${props.children || paths[props.page].title}${props.num ? ` #${props.num}` : ""}`;

  document.title = `${title} - ${terms.company}`;

  if (props.num) {
    title += `: ${((props.person && (props.person.length > 0)) ? props.person.join(", ") : props.context)}`;
  }

  return (
    <h1 className="center-align">{title}</h1>
  );
};