import React, { useEffect } from "react";
import { FormSelect, Datepicker, Timepicker } from "materialize-css";

import { theme } from "../../utils";
import "./style.css";

import { Col } from "../Grid";

//  Form tag wrapper
export const Form = props => {
  return (
    <form className={props.className} action={props.action} method={props.method}>
      {props.children}
    </form>
  );
};

//  Fieldset wrapper
export const Fieldset = props => {
  return (
    <fieldset className={`${props.align || "center"}-align`}>
      <legend>{props.name}</legend>

      {props.children}
    </fieldset>
  )
};

//  Materialize Input that can be used for any kind of input tag
export const Input = props => {
  return (
    <Col size={props.size || "s12"} styles="input-field">
      <input className={`validate ${(props.autocomplete) ? "autocomplete" : ""}`} id={props.id} type={props.type || "text"} disabled={props.disabled} placeholder={props.placeholder} min={props.min} max={props.max} onChange={props.handleInputChange} />

      <label className={`${props.colors || theme.link} ${(props.active) ? "active" : ""}`} htmlFor={props.id}>
        {props.label}
      </label>

      {props.children}
    </Col>
  );
};

//  Materialize Radio input
export const Radio = props => {
  return (
    <label className={(props.list) ? "input-list" : "input-line"}>
      <input type="radio" name={props.name} value={props.value} onChange={props.handleInputChange} />

      <span>{props.children}</span>
    </label>
  );
};

//  Materialize Radio input button
export const RadioBtn = props => {
  return (
    <label className={`${(props.list) ? "input-list" : "input-line"} radio-button`}>
      <input type="radio" name={props.name} value={props.value} onChange={props.handleInputChange} />

      <span>{props.children}</span>
    </label>
  );
};

//  Materialize Checkbox input
export const Checkbox = props => {
  return (
    <label className={`${(props.list) ? "input-list" : "input-line"} ${props.text || theme.link}`}>
      <input type="checkbox" name={props.name} value={props.value} className="filled-in" disabled={props.disabled} onClick={props.handleClick} />

      <span>{props.children}</span>
    </label>
  );
};

//  Materialize Select input
export const Select = props => {
  useEffect(() => {
    FormSelect.init(document.querySelectorAll("select"), {});
  });

  return ( 
    <Col size={props.size || "s12"} styles="input-field">
      <select id={props.id} name={props.name} className={props.className} onChange={props.handleInputChange} defaultValue={props.defaultValue}>
        {props.children}
      </select>
    </Col>
  );
};

//  Materialize Option
export const Option = props => {
  return (
    <option key={props.value} value={props.value} disabled={props.disabled} data-icon={props.icon}>
      {props.children}
    </option>
  );
};

//  Materialize TextArea
export const TextArea = props => {
  return (
    <Col size={props.size || "s12"} styles="input-field">
      <textarea id={props.id} className="materialize-textarea" placeholder={props.placeholder} onChange={props.handleInputChange}></textarea>

      <label className={`${props.color || theme.link} ${(props.active) ? "active" : ""}`} htmlFor={props.id}>
        {props.children}
      </label>
    </Col>
  );
};

//  Materialize Datepicker
export const DatepickerM = props => {
  useEffect(() => {
    Datepicker.init(document.querySelectorAll(".datepicker"), {
      format: "mmmm dd, yyyy"
    });
  });

  return (
    <Col size={props.size || "s12"} styles="input-field">
      <input type="text" className="datepicker" id={props.id} disabled={props.disabled} required={props.required} />

      <label htmlFor={props.id}>
        {props.children}
      </label>
    </Col>
  );
};

//  Materialize Timepicker
export const TimepickerM = props => {
  useEffect(() => {
    Timepicker.init(document.querySelectorAll(".timepicker"), {
      twelveHour: false
    });
  });

  return (
    <Col size={props.size || "s12"} styles="input-field">
      <input type="text" className="timepicker" id={props.id} disabled={props.disabled} required={props.required} />

      <label htmlFor={props.id}>
        {props.children}
      </label>
    </Col>
  )
};