import React, { useState } from "react";
import Markdown from "react-markdown";

import { datetime } from "../../utils/time";
import { theme } from "../../utils";
import "./style.css";

import { List, ListHeader, ListItem } from "../List";
import { StatusMsg } from "../Msg";
import { Checkbox } from "../Form";

//  Wrapper for Discord Chats
export const DiscordChatWrap = props => {
  return (
    <List id={props.id}>
      <ListHeader colors={props.colors || theme.base}>
        <h3 className="center-align">{props.channel}</h3>

      {(props.readOnly)
        ?
        <p className="center-align">
          <strong>Begin</strong>: {`${datetime(props.startTime, props.utc)} (${(props.utc) ? "UTC" : "local"})`}<br />
          <strong>End</strong>: {`${datetime(props.endTime, props.utc)} (${(props.utc) ? "UTC" : "local"})`}
        </p>
        : <StatusMsg>{props.statusMsg}</StatusMsg>
      }
      </ListHeader>

      {props.children}
    </List>
  );
};

//  Component for Discord Message
export const DiscordChat = props => {
  const [name, setName] = useState("Unknown");

  const uname = async () => {
    let userStr = await props.name;
    setName(userStr);
  };
  
  uname();

  return (
    <ListItem colors={props.colors || theme.accent} text={props.text || theme.link}>
    {(props.readOnly)
    ? <Markdown source={`**${name}**: ${props.message} [${datetime(props.time, props.utc)} (${props.utc ? "UTC" : "local"})]`} />
    : <Checkbox name="discordChats" value={props.chatId} text={props.text}
        handleClick={props.handleClick}>
       <Markdown source={`**${name}**: ${props.message} [${datetime(props.time, props.utc)} (${props.utc ? "UTC" : "local"})]`} />
      </Checkbox>}
    </ListItem>
  );
};