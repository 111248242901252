import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const Osmanthus = props => {
  return (<Container>
    <Row>
      <Col>
        <h1>17 September 2020: Osmanthus Protocol Update</h1>

        <p>Fourth remote update. Packet location traced to private virtual network. Packet location traced to Oakland, California, United States.</p>

        <p class="protocol" id="osmanthus-protocol">OIZGia8ySNSFQRudaUv2kfA2BBECEqa2m1RFZ/E71wy5ES27mJkB0x4GosZoxQnwvhxVPaMVsBy+8pDq9vdoo77xJUKU0YERULCRu9T07XIpJF7VTFTJjFVW5+jL0BAlvv4IRBkXLFxvCUjrB2vFPI9uva2wG9fEYDOlBS0n514qUeSUWSFZQObEuOOoaSt9mKDIv5B8sJF4//qg/JTnsxEyZb08Q12fRoMjPhuQCjrVvX8b1aYBmfm782k7oDH11466FhHO1WXCzb4Pu0G4Bg0hSe32MFz0T3mq9SRcGIkiOywShuk3edecI1wlZ87iHUOT5Lv/INddcJ/A/jwElJ2WD79VmjbUalgihxo6N0Wl5AveBUkRDKpi5RyVN2yRwHWSMw5xmdEFbVIDnc7Yl3l+6RsSb7Y1tS2g7/qkeh3vG9DaywHZZC1xyK8tOnUfqFlxg4mswqFyiMbFrs1KagaATtyrQgSvKX1AEoMvSQR0/U5h/DotnjvqI6ebXOn6pNYjxa1F+iImzTUTZZqx1Nf5pa2CzCq27AXatPscsURWcPQ3Y++o4bxha+JOJc16awy9EpQ+yQDsb0hnzqQz4AdJTC2/i+HU6kW2l//Hinj28NSBmMAPpXl3x00cJ+HxOlZjBgwlNJWlbh1vPcIA4MMO8iQMqH6F1QxyvdnTmoj2x6qA5tDl91T6a/l7NoVQ</p>
      </Col>
    </Row>
  </Container>)
};
