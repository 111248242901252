import React from "react";

import { terms, email, theme } from "../utils";

import { Container, Row, Col } from "../components/Grid";
import { LinkIn, Email } from "../components/Btn";
import { CarouselM, CarouselItem } from "../components/Carousel";

const Home = props => {
  const slider = [
    {
      image: "/images/home/cloud_light.jpg",
      alt: "Clouds over the ocean",
      caption: <div className="slide-text slide-text-center slide-text-middle slide-full">
        <h1>Enter a new reality</h1>
      </div>
    },

    {
      image: "/images/home/fish_reef.jpg",
      alt: "Fish swimming in a tank",
      caption: <div className="slide-text slide-full slide-text-center slide-text-middle slide-cyan">
        <h1>Dive into new immersive experiences</h1>
      </div>
    },

    {
      image: "/images/home/spoopy_woods.jpg",
      alt: "Woods outside the building",
      caption: <div className="slide-text slide-full slide-black">
        <h1>Coming soon: The Veiled Forest</h1>
      </div>
    },

    {
      image: "/images/home/vr_headset.jpg",
      alt: "Man testing VR headset",
      caption: <div className="slide-text slide-half slide-left slide-purple">
        <h1>Building towards a VR experience that transcends reality</h1>
      </div>
    },

    {
      image: "/images/home/conference_room.jpg",
      alt: "Stark white conference room",
      caption: <div className="slide-text slide-half slide-top slide-text-bottom">
        <h1><LinkIn page={6} color={theme.text}>Join our team today!</LinkIn></h1>
      </div>
    }
  ];

  return (<>
    <CarouselM
      slider={true}
      fullWidth={true}
      indicators={true}
      styles="slider-home"
    >
    {slider.map((slide, index) => <CarouselItem key={index} link={`28mh${index}`} image={slide.image}>
      {slide.caption}
    </CarouselItem>)}
    </CarouselM>

    <Container>
      <Row>
        <Col>
          <p>Thank you for your interest in {terms.company}!</p>

          <p>Due to our recent merger with Jade Imperium Holdings, 28 Moon House has been going through a transition period where we will be hiring new employees to fill in new positions. All employees must bring their company identification cards and present identification when requested.</p>

          <p>With the new positions being filled, we encourage our veteran staff to help new employees adjust to the company environment. This includes explaining the task workflow, introducing senior staff members, and reviewing company policies.</p>

          <hr />

          <p>Until environmental reviews are done, management highly discourages employees to wander off into areas not open to them. These areas may still have damage from the previous incident on April 4 and are in need of repairs. Report any trespassers to Security as soon as possible.</p>

          <hr />

          <p>As part of the recent corporate restructuring, the previous Head of the Maintenance Department, Mr. Lawrence Gee is retired as of September 2nd. At the moment, his position is open for applicants.</p>

          <p>For all work requests, leave a notice in the Maintenance Office in the basement and a member of the Maintenance Department will fulfill that request as soon as possible.</p>

          <hr />

          <p>Since Dr. Shaw has taken an indefinite leave of absence, the Infirmary on the second floor is closed. All medical emergencies should be directed towards the West Mountain Clinic until he returns.</p>

          <hr />

          <p>Development of The Veiled Forest has been delayed due to issues that arose from alpha testing. If any testers are still suffering from effects from the VR headset, they are encouraged to pay a visit to Dr. Shaw or the Infirmary for further examination.</p>

          <hr />

          <p>For serious inquiries, email <Email email={email.support} />.</p>
        </Col>
      </Row>
    </Container>
  </>);
};

export default Home;
