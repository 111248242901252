import React, { useState, useEffect } from "react";
import { useRoutes, useRedirect } from "hookrouter";
import ReactGA from "react-ga";

import { terms } from "./utils";
import API from "./utils/API";
import "./App.css";

//  Header and Footer components
import Header from "./components/Header";
import Footer from "./components/Footer";

//  Pages
import { paths, pages } from "./pages";
import NoPageFound from  "./pages/NoPageFound";

const App = () => {

  const [name, setName] = useState();
  const [rank, setRank] = useState();
  const [uid, setUid] = useState();

  //  Get the session and then set the states
  useEffect(() => {
    API.verify()
      .then(session => {
        setRank(session.rank || 0);
        setName(session.name);
        setUid(session.uid);
      })
      .catch(err => console.log(err));
  }, []);
  const routes = pages(rank, name, uid);

  //  Redirects
  useRedirect(paths[4].path, paths[3].path);  //  Login -> Dashboard
  
  const routeResult = useRoutes(routes);

  useEffect(() => {
    //  Set the Google Analytics page view
    ReactGA.initialize("UA-152853638-1");
    ReactGA.pageview(window.location.pathname);
    
    //  Set the page title
    document.title = `${(routeResult !== null) 
      ? paths[routeResult.key].title 
      : "Page Not Found"} 
      - ${terms.company}`;
  }, [routeResult]);
  
  return (
    <>
    <Header user={name} rank={rank} />
    <main>
      {routeResult || <NoPageFound />}
    </main>
    <Footer />
    </>
  );
};

export default App;
