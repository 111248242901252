import React from "react";

//  Material Icon
export const Icon = props => {
  return (
    <i className={`material-icons ${props.size || "small"} ${props.color || ""}`} onClick={props.handleClick}>{props.children}</i>
  );
};

//  Font Awesome Brand Icon
export const IconFAB = props => {
  return (
    <i className={`fab fa-${props.brand} ${props.size || "small"} ${props.color || ""}`} onClick={props.handleClick}></i>
  );
};

//  Font Awesome Solid Icon
export const IconFAS = props => {
  return (
    <i className={`fas fa-${props.icon} ${props.size || "small"} ${props.color || ""}`} onClick={props.handleClick}></i>
  )
};