import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const Orchid = props => {

  return (<Container>
    <Row>
      <Col>
        <h1>21 June 2020: Orchid Protocol Update</h1>

        <p>First remote update. Packet location unverified.</p>

        <p className="protocol" id="orchid-protocol">d5/pW+4h1Xp5gXc4FRDKntmChoDoQJM4PNNQkpBOiOPbwlEBm7mfNmhKU7uuRVlhrNLmC5adF1vhk/tpl032VPUa2nAfjWTvIpJ0+kP2CK99v9T5fjaTLrxpsLNF6UZoEBbi9syy6tGeb6EihQyVIT67dtASJFle1+DnMVfVGvHzOC1oOqqN8sPqIQ/QZGPByBAeHkxzslqJJIYUNMiVpWSGJZBfAuHe3TRZjS+pISTylYCTqwsF2HLoasVSUwga9FBOoSsH1ihEgaxVU2jawQFrEfmsjo64RCd8gGJEUNsnqEUcQPn+63ZD88bGhm1teoe6/BziH0B+/D2znamwK4Ld+B3SnM2LOsGJev1yITmNmPZ8Af9tgmc+4ZtU+f6J3mzcCj5c3A3a2/DyCpfIYgKL7qbvNVgrAELTjP57dtp1+VJLZhfKivUsRXhE+HqKxoJH1gC5NSYYl5GrvtkSSh6SEJoLZNAhTSV2T0hgpcepsVbqCXGYkkKGgXcZxgg2PUC0IH9tClm3JuXBd2XYf+8BIwUzkIKD6kXaPItXOyOJsr5eXpf/nq/u3Er9d0a+217DjX3yCPenxvZf4b5Lw1AZIzGQlq7KAaZXT5tWGcyGC2/hwHF/9ZTkIYf8PVjURPHf5bvKFHVy07FwCgSkgMfjbAQW1NkjkwVn/ghM2MWnRGsuCSBgHdJC1OKkyWKBb07a1Ya0vXtaxsWVg4rqkr3iiQC439m+IqenoXObWCxboJ1FGWiByFL5nop5LMnG0xx+SM8Ybs7BiR65mj+5w5ejEUj/QGVCu+gmxv+0iB/C0rWEmUb4kZdU7SP7HRj9zL3hyn3jRIT5Vx28FPue3DEnvwOLupvzlFXd8hhi0oI=</p>
      </Col>
    </Row>
  </Container>);
};
