import React, { useState, useEffect } from "react";

import { terms, theme, bot } from "../utils";
import API from "../utils/API";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { Card } from "../components/Card";

const Division = props => {
  const [onboarding, setOnboarding] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      API.getUser(props.uid)
        .then(user => {
          const roles = user.data.roles.map(role => role.role);
          
          //  Check if the User needs to choose their division
          if (roles.indexOf("Intern") !== -1) {
            API.getExamPass(props.uid)
              .then(exam => {
                const exams = exam.data.map(exam => exam.name);

                if (exams.indexOf("Onboarding") !== -1) setOnboarding(true);
              })
              .catch(err => console.log(err));
          }

          setLoaded(true);
        })
        .catch(err => console.log(err));
    }
  }, [loaded, props]);

  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />
        </Col>
      </Row>

      <Row>
        <Card size="s12 m6 l3" 
          color={theme.division[0]}
          title={terms.division[0]}
        >
          <ul>
            <li>Human Resources</li>
            <li>Public Relations</li>
          </ul>
        </Card>

        <Card size="s12 m6 l3"
          color={theme.division[1]}
          title={terms.division[1]}
        >
          <ul>
            <li>Accounting</li>
            <li>Legal</li>
            <li>Maintenance</li>
            <li>Security</li>
          </ul>
        </Card>

        <Card size="s12 m6 l3"
          color={theme.division[2]}
          title={terms.division[2]}
          text={theme.textAlt}
        >
          <ul>
            <li>Infirmary</li>
            <li>Research</li>
            <li>Testing</li>
          </ul>
        </Card>

        <Card size="s12 m6 l3"
          color={theme.division[3]}
          title={terms.division[3]}
        >
          <ul>
            <li>Customer Support</li>
            <li>Development</li>
            <li>Tech Support</li>
          </ul>
        </Card>
      </Row>

      {onboarding && <Row>
        <Col>
          <StatusMsg>Choose which division you would like to join</StatusMsg>

          <ol>
            <li>Go to the <code>#onboarding</code> channel.</li>
            <li>Invoke the Tiger Head OS and put in which division you wish to join.</li>

            <ul>
              <li>
                <strong>{terms.division[0]}</strong>:
                <pre>{bot.division} 1</pre>
              </li>

              <li>
                <strong>{terms.division[1]}</strong>: 
                <pre>{bot.division} 2</pre>
              </li>

              <li>
                <strong>{terms.division[2]}</strong>: 
                <pre>{bot.division} 3</pre>
              </li>

              <li>
                <strong>{terms.division[3]}</strong>: 
                <pre>{bot.division} 4</pre>
              </li>
            </ul>
          </ol>

          <StatusMsg><strong>You may only choose which division to join once!</strong></StatusMsg>

          <StatusMsg>If you wish to change your division later, consult a Human Resources Officer.</StatusMsg>
        </Col>
      </Row>}
    </Container>
  );
};

export default Division;