import React, { useState, useEffect } from "react";

import API from "../utils/API";
import { archived, terms, theme } from "../utils";
import { datetime } from "../utils/time";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { LinkIn } from "../components/Btn";
import { DiscordChatWrap, DiscordChat } from "../components/DiscordChat";
import { SwitchUTC } from "../components/Switch";

const Archive = props => {
  //  Database loading properties
  const [archives, setArchives] = useState([]);
  const [receiver, setReceiver] = useState(false);
  const [log, setLog] = useState();
  const [loaded, setLoaded] = useState(false);
  const [utc, setUtc] = useState(false);

  //  Log properties
  const [person, setPerson] = useState([]);
  const [context, setContext] = useState("");
  const [channel, setChannel] = useState("");
  const [cleanLog, setCleanLog] = useState([]);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const setup = {
    user: chat => {
      if (chat.user.bot) {
        if (chat.user.related.length > 0) {
          return chat.user.related[chat.display].bot_name;
        } else if (chat.user.username) {
          return chat.user.display;
        }
      }

      let uname = chat.user.username;
      uname = uname.substring(0, uname.lastIndexOf("#"));

      const userStr = (chat.user.nicknames[chat.display] === uname)
      ? uname
      : `${chat.user.nicknames[chat.display]} (${uname})`;

      return userStr;
    }
  }

  useEffect(() => {
    if (!loaded) {
      //  Get all logs of the type
      API.getLogType(props.logType, {
        rank: props.rank
      })
        .then(logs => {
          const logsArr = logs.data.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
          setArchives(logsArr);

          //  Sets the archive log if there's a number
          if (props.num && ((props.num - 1) < logsArr.length)) {
            API.getLog(props.logType, props.num, {
              rank: props.rank
            })
              .then(log => {
                setLog(log.data);
                setContext(log.data.context);
                setChannel(log.data.channel.channel);

                setCleanLog(log.data.cleanLog);
                setStart(log.data.cleanLog[0].timestamp);
                setEnd(log.data.cleanLog[log.data.cleanLog.length - 1].timestamp);

                if (log.data.person) {
                  let persons = log.data.person.map(user => user.display);
                  
                  setPerson(persons);
                }
              })
              .catch(err => console.log(err));
          }

          //  Set if the user is allowed to receive transmissions
          if (props.logType === archived[3]) {
            setReceiver(true);
          }

          setLoaded(true);
        })
        .catch (err => console.log(err));
    }
  }, [loaded, props, log]);
  
  return (<>
  {((props.rank > 0) 
  && (((props.logType === archived[3]) && receiver) 
    || ((props.logType === archived[2]) && (props.rank > 1))
    || (-1 < archived.indexOf(props.logType) < 2)))
    ? <Container>
      {(props.num)
      ? <>
        <Row>
          <Col>
            <Title page={props.page} num={props.num} person={person} context={context} />
          </Col>
        </Row>

        {(log)
        ? <><Row>
          <Col size="s12 m9">
            <p className="center-align">{context}</p>
          </Col>
          
          <Col size="s12 m3">
            <SwitchUTC handleInputChange={e => 
              setUtc(e.target.checked)} />
          </Col>
        </Row>
        
        <Row>
          <Col>
            <DiscordChatWrap
              readOnly={true}
              color={`discord-header ${theme.tint}`}
              channel={`#${channel}`}
              startTime={start}
              endTime={end}
              utc={utc}>
              {cleanLog.map(chat => <DiscordChat 
                key={chat.chatId}
                readOnly={true}
                chatId={chat.chatId}
                name={setup.user(chat)}
                message={chat.message}
                time={chat.timestamp}
                utc={utc}
              />)}
            </DiscordChatWrap>
          </Col>
        </Row>
        </>
        : <Row>
          <Col>
            <StatusMsg>This archived {props.logType} does not exist or you do not have access to it.</StatusMsg>
          </Col>
        </Row>
        }
      </>
      : <Row>
        <Col>
          <Title page={props.page} />

          <ul>
          {archives.map((log, index) => 
            <li>
              <LinkIn page={props.page + 1} num={index + 1}>
                {(log.person.length > 0) ? log.person.join(", ") : log.context}
              </LinkIn> [{datetime(log.timestamp, false, true)} (local)]
            </li>
          )}
          </ul>
        </Col>
      </Row>
      }
    </Container>
    : <Container>
      <Row>
        <Col>
          <StatusMsg>You do not have access to the {terms.company} archives. Make sure you are logged in via <LinkIn page={4} />.</StatusMsg>
        </Col>  
      </Row>  
    </Container>}
  </>);
};

export default Archive;