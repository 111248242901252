import React from "react";

import { Container, Row, Col } from "../../components/Grid";

export const FourGentlemen = props => {
  return (<Container>
    <Row>
      <Col>
        <h1>The Four Gentlemen</h1>

        <p>The Four Junzi (四君子) refer to the plum flower, the orchid, the bamboo, and the chrysanthemum as a group. In Confucianism, the term <em>junzi</em> is used to describe a morally upright, pure, and righteous person. These plants were attributed to such qualities in Chinese art.</p>

        <p className="protocol" id="third-gentleman">PP/4rB3Bjy3JqvGfhZeG7et8YzsnwHAJvE9havpOVgrVKKtYmYxmSYQsgtygR6s8NSgbGIFhhhZ93tgTWYLi6FXwU7p3RbrH5FoB4Y2NxCLkwc8lRMEJ1HH322I3eWhe8IbG/4mDN2IPsDUiA1xhldbI+Q7sd97Xv6SWi1qusQDrXaoRXofOW4coTK2RsCnqnyGb7GMkVEIBzoEvfgsyvNnbPaAoVzyuziU3sRqG8MtU2kWMHjKF3z2a54eT8iTJI769P4ximHbfIsSAy5mPldm9bO5uFwsabySjP/TOERP+lBOEzRHS8TvRUjGaDD+9E3CBKWtBNnemsY2kKsSiyPxzeUMPPxjAnFC827KyDNU1CE9tYT3aYXDp7teuX7XD+GBYMFRmm0OdNrgW9KSZAENJ1GSLhE71nu7AmgCkxwK/CSyTWincMtNqh26RSuUObZ9iSY8jpRsHV18pLtb6jlKtLWV/R+B83jpY6pPS6yiZYmNZpIf+253v5erPwm/5VAgYqg4k54OKDTNVS3XvlXC0wfRB7yFVyW+Ci6FqK7riBL7J3kKynYfyWWAVsB48+8MPP4nkke9x0iAntVSavmEmtzq21S+GgYpPJgmplw+PJro2IXxtEklhfq/PbMAxbUwTaLazmhL7wkpBZKeWUMqnNyA73bH0AgZTT5/FAzAt8ucRUxXQCvY5tfvc4UUcEqdwaJUEcQ==</p>
      </Col>
    </Row>

    <Row>
      <Col size="s12 m6">
        <h2>Plum Flower</h2>

        <p><strong>Season</strong>: Winter</p>

        <p>Called <em>meihua</em> (梅花) in China, the plum blossom is beloved for its vibrant color when blooming in winter. Its ethereal beauty and subtle fragrance have come to represent the principles and values of virtues in Confucianism.</p>

        <p className="protocol" id="second-gentleman">m6N4nrVnJgKWXOV9AQAo49uXp9ScwiX0u/2lABkQyQ9uSTW3vp1pzACDQyTXV2BiGorMv9/b9o09Q6EKTpJ+XcxOSjXZ2XeSVS/425VtJa4W20Yrf2xSN8AQ14UWK9zMLXGhRKogEJGx9lojBxts6iX7iDzWTPDm8PVwNLKTnZaKsXq1t57z4YynYyczUpvgQFMFqXu4ZUbBdoGzT41a4JVSZhpyd3BDxx/WUbCQUagTPORgNNiTf6eLwKjDps3SJcHQz7LtlrKgRBvOP6FKE0u/fS7x13RHtKST/m9oafUS7/zzQNjqm0xjT2up5RVAYRGBcuHt8329UHZO5vemTjqrhjcYlGgTNM7GhTFupxL195Jog5RnJyESyVxgwer4drSTYDSyNBoON9BgyL0/zq//QzASfo3FgCZJ+aPkZCeOWSzi1crksiw+dcC9D66jhrIL6UnJDwIyjj4yupMfux9+ln1AXOKUyaK2vgyZGeQJBruVO7BLN8nBIosKN8OatfxShHYFtXhWGYHzboVv2mES9Ul80XoOuIu2eRyseWukcqACrbM5cGMBzJaMLMQ8bG2C3/YGK6cMpeHs/P7TpqYovb7mhjeWSeutMXTpEBZoz2cAM6lkdbsdaI8+ciDHVeLsopGj+o8=</p>
      </Col>

      <Col size="s12 m6">
        <h2>Orchid</h2>

        <p><strong>Season</strong>: Spring</p>

        <p>Called <em>lanhua</em> (兰花) in China, the orchid has been a favorite of Chinese scholars as it represents integrity, nobility, and friendship. With those meanings in mind, the orchid could be compared to a virtuous man in Confucian thought.</p>

        <p className="protocol" id="first-gentleman">5g/dVKzDgrFpZJI8/GLhflWariJvvrLjFR2ojncjqQcfviLsZd16fSfl7HwaLLoBmX01loJMgH4WQPUdxeSUdNXYPgJSEFA4lufwWwhMhB38my0EPqZmZ9AZ++I+4/aPUWYmSWCdBrh5RqMwRvFFF6qyeQxxTh6FDmTwfiy5bb1BA/9eA4udavNIg241hEMYDwwaQ7do8reon2eSbzgU60sxdc+VcJ43CaG1dgnh4zB0xu5/ovWEcXbeLL8FjxtnG+9Pjvn/yCt0PQV1oaRYSfTiKm6PgnnLOWh8IFbaSvZyWXLJJaFsfSqZJSB9o/MKg8mGlBzMyF+aJLeGh8FBo02l/s62FcgpACouGgbOaBki9AsDUqh817KUIaNUnOhnqDWKdesW4iY9lqyyJivYZhy+B+PgLtYvxOVEe0AuMi6cx2l5rqgaWxGHKbEi1p5bOqhLG8Jn7oeqjlVOpfbZ1wryhz3U74EHJJObpU4dB/1BexUBqYoOBz+8TJcSpmYz0JNE/MNSP4sfKDHr/FPbH7cBoH8KjYM37JeJlE28Hf36ic9qJvl5F/jHeH6MDoRBpmVfEA5CV9rJoyzpfAOeVQpTOZRaSVfFJjknFo8sSHgtAA5RpgFcvfxo5iI1dZTs3X3lv9hTZ2DcFUHOO2Of6jUPCgLfRNmtJ/ZPuuMZaFXRZrCv+xHmt5rW50tVDnguW8goTxPYZbrqMYtCFdxPsWEvSxBtmbEfTXXOn/+HTFFNYd4AwLm57Q==</p>
      </Col>

      <Col size="s12 m6">
        <h2>Bamboo</h2>

        <p><strong>Season</strong>: Summer</p>

        <p>Called <em>zhu</em> (竹) in China, bamboo symbolizes uprightness due to its long life. While not physically strong, it is nevertheless praised for its comparison to a gentleman who is mentally strong yet openminded enough to accept things to his benefit without prejudice or arrogance.</p>
      </Col>

      <Col size="s12 m6">
        <h2>Chrysanthemum</h2>

        <p><strong>Season</strong>: Autumn</p>

        <p>Called <em>juhua</em> (菊花) in China, the chrysanthemum is a common flower that represents nobility, elegance, and longevity. This flower holds particular significance during the Double Ninth Festival as it is believed to have cleansing qualities.</p>

        <p class="protocol" id="fourth-gentleman">lyAyeZ8GVWqymXEaQ9fLfuwKv5WjF7qC0v0fROElx6vrLGiHDJHyoF9pAwG0FxNCuM2IfzwPNcwtE+mmZgH8eXAqYROkbsOFAGrnntCq2aEtXpFqVltjlFndVt3gItXWIZIGHek6s8zkPLc2Z81BZrYXilq5TTcZzNF7E181+Tn6jtwtQcSJxLpD8DT3OQQhvG+KYudyCf6xVFp+bozPJBSJAo/jhGkvnEgU8jgEmyrxapJOH2bxPzNzQmFw1bFCbYK+qb5+Pzfg1qvb6rl8cWDMBIbW7IYES5NfLWEXkLfH9gBwqG3xTaFK1TNMFN06zPjlMEf6GY/PM6tHGXntIzPyTJ9zS6swRIDjV5ZenRQ+4SZCt7mZnUEFT3THjVdNU8DtULUYamDwF62yHRTHvw0744F3rQmPSDFXWh1kGBRbQ32kfM12P69Ax1NW7JT44BTe0aDknNWcUMv+mNAR2u0dnBf/4ybREqjXz27qzRioJzUGMg9dnH8st1Ec9rJhzbLAkxuhOY0N8ZSiatLM9ZGGGnfF1XZ16LhL4P1pZzKp0VNllaXaDomwCgfygo6YwzO0T2vBT7/r1b01DcY82P/uLIT8ussRhIFC3vTuue538lrQ4Hg9u26RuN6knizBm7giK4kUCd4p5Erxwgyp2jCuiJ3lEJvZo9C7Duuip27wU3RrmDf94buj+ppdLCkUz4bEmZtUP60U6ytUwE524nSZj9o+RnQrKi37AFei6mfW8x3wAGzj31fl10BPxSv8gaccJXZ4p14=</p>
      </Col>
    </Row>
  </Container>)
};
