import React from "react";
import { A } from "hookrouter";

import { theme } from "../../utils";
import { paths } from "../../pages";
import "./style.css";

import { Icon } from "../Icon";

export const List = props => {
  return (
    (props.list === "ol") 
      ? 
      <ol className={`collection ${theme.accent}`} id={props.id}>
        {props.children}
      </ol> 
      : 
      <ul className={`collection ${theme.accent}`} id={props.id}>
        {props.children}
      </ul>
  );
};

export const ListHeader = props => {
  return (
    <li className={`collection-header ${props.colors || theme.tint} ${props.text || theme.text}`}>  
      {props.children}
    </li>
  )
};

export const ListItem = props => {
  return (
    <li className={`collection-item ${props.colors || theme.accent} ${props.text || theme.link}`}>
      {props.children}
    </li>
  );
};

export const NavItem = props => {
  return (
    <li>
      <A href={paths[props.page].path} className={`sidenav-close waves-effect ${theme.text}`}>
        <Icon color={theme.text}>{props.icon}</Icon>
        {props.children || paths[props.page].title}
      </A>
    </li>
  );
};