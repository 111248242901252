import React from "react";

import { staff } from "../utils/staff";

import { Container, Row, Col } from "../components/Grid";
import { Title } from "../components/Msg";
import { ProfileCard } from "../components/Card";

const Team = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />
        </Col>
      </Row>

      <Row>
        {staff.map((person, key) => (key < 5) && <ProfileCard key={`${person.fullName}-${key}`} rank={props.rank} member={key} />)}
      </Row>
    </Container>
  );
};

export default Team;