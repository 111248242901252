import axios from "axios";

const API = {
  //  Redirect to login URL
  login: () => axios.get(`/api/user/login`),

  //  Verifies the session
  verify: async () => {
    try {
      //  Retrieves the session data
      const session = await axios.get(`/api/user/verify`);

      //  Removes the cookie before retuning the session data
      delete session.data.cookie;
      return session.data;
    }

    catch (err) { console.log(err); }
  },

  //  Deletes the session
  logout: () => axios.get(`/api/user/logout`),

  //  Discord related functions
  getChannels: () => axios.get(`/api/discord/channels`),
  extractChannels: () => axios.post(`/api/discord/channels`),
  getChannel: (id) => axios.get(`api/discord/channels/${id}`),
  extractRoles: () => axios.post(`/api/discord/roles`),
  getRoles: () => axios.get(`/api/discord/roles`),
  updateRole: (id, query) => axios.put(`/api/discord/roles/${id}`, query),

  //  User related functions
  getUserAll: () => axios.get(`/api/user/all`),
  getUser: (id) => axios.get(`/api/user/${id}`),
  updateUser: (id, query) => axios.put(`/api/user/${id}`, query),

  //  Chat related functions
  extractChats: (channel, query) => axios.post(`/api/discord/messages/${channel}`, query),

  //  Log related functions
  createLog: (query) => axios.post(`/api/chat/generate`, query),
  getLog: (type, num, query) => axios.post(`/api/chat/${type}/${num}`, query),
  getLogType: (type, query) => axios.post(`/api/chat/${type}`, query),
  getLogAll: (query) => axios.post(`/api/chat/logs`, query),
  updateLog: (id, query) => axios.put(`/api/chat/logs/${id}`, query),
  deleteLog: (id) => axios.delete(`/api/chat/logs/${id}`),

  //  Exam related functions
  getExam: (name) => axios.get(`/api/exam/${name}/render`),
  getExamNeed: (id) => axios.get(`/api/exam/needed/${id}`),
  getExamPass: (id) => axios.get(`/api/exam/passed/${id}`),
  userPassed: (id, userId) => axios.put(`/api/exam/${id}/passed`, {
    user: userId
  })
};

export default API;