import React from "react";
import { Container, Row, Col } from "../components/Grid";

const NoPageFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="center-text">404 - Page Not Found</h1>

          <p>Sorry the page you were looking for could not be found.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default NoPageFound;