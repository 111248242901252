import React from "react";

import { terms, email } from "../utils";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { LinkIn, Email, LinkOut } from "../components/Btn";

const PrivacyPolicy = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />

          <StatusMsg>
            <strong>Last Updated: </strong>
            <em>December 26, 2019</em>
          </StatusMsg>

          <section id="privacy-intro">
            <p>Thank you for visiting <strong>{terms.website}</strong> (the "Service" or the "Site"). The Site is owned and operated by {terms.company} ("us", "we", or "our"). If you would like to contact us about anything contained on the Site, contact us at <Email email={email.support} />.</p>
            
            <p>We respect and protect the privacy of our users. This Privacy Policy tells you how we collect and handle the personal information that you provide to us which forms part of and should be read in conjunction with our <LinkIn page={2} />.</p>
          </section>
          
          <section id="privacy-collection-use-information">
            <h2>Collection and Use of Personal Information</h2>

            <p>The term <em>Personal Information</em> in this Privacy Policy means any information from which your identity is apparent or can be reasonably ascertained.</p>

            <p>The Service can be used without telling us who you are or revealing other Personal Information. All disclosures of Personal Information is voluntary the collection of information comes from what you choose to directly provide us.</p>

            <p>The Service depends on Personal Information to engage in the Game requires collection of Discord information for full Participation in the Game. The Personal Information collected is broken down below for each third-party service we use for collecting Personal Information.</p>

            <p>Other than circumstances such as unlawful activity or serious threats to health and safety, we do not share Personal Information with government agencies. If you ask us about an issue that needs to be dealt with by a government agency, we will provide you with the necessary details to make contact yourself. You may opt out of further contact from us at any time.</p>

            <p>We will not pass any collected Personal Information to any third-party companies for any reason.</p>
          </section>

          <section id="privacy-discord">
            <h3>Discord</h3>

            <p><strong>Discord</strong> is a voice and text-chat  application. The Game is primarily run on the {terms.company} Discord server ("Server") that acts as the main hub for users ("Players") and interaction between the Players and us in the Game. To facilitate full Participation in the Game, access to the Service with a Discord account is necessary.</p>

            <p>From Discord, we collect the following from you upon accepting the invitation to our Server:</p>

            <ul className="browser-default">
              <li><strong>Username</strong> - this is how we primarily identify you as a Player in the Game</li>
              <li><strong>Nickname</strong> - this is how you identify yourself to other Players in the Game and in the Server. This is by default your Discord username, though you may choose to change it to a character name of your choosing</li>
              <li><strong>Avatar</strong> - the image that you choose to represent yourself as a Player in the Game</li>
              <li><strong>Role(s)</strong> - this is how we ascertain your current status in the Game</li>
            </ul>
          </section>

          <section id="privacy-reddit">
            <h3>Reddit</h3>

            <p><strong>Reddit</strong> is a social news aggregation and discussion website. Parts of the Game that are not on the Discord server are posted in the {terms.company} subreddit ("Subreddit"). Posts and puzzles from us are primarily posted on the Subreddit. While all posts on the Subreddit can be viewed by the public, we encourage the disclosure of your Reddit handle for participating in puzzle solving.</p>

            <p>From Reddit, you may disclose the following either through sending a direct message to us on the Server or by emailing us at <Email email={email.support} />:</p>

            <ul className="browser-default">
              <li><strong>Username</strong> - this is how we identify you as an active puzzle-solver and Player on the Subreddit.</li>
            </ul>

            <p>In conjunction with your Discord account information provided, we use this information to update your Player status in interactions on the Server and the Roles as defined in the <LinkOut link="#privacy-discord">Discord section</LinkOut>.</p>
          </section>

          <section id="privacy-twitter">
            <h3>Twitter</h3>

            <p><strong>Twitter</strong> is a social networking service. The {terms.company} Twitter account and related accounts ("Twitter Feed") can be viewed without an account to look at puzzle hints and flavor text for the Game. Occasionally, we will use the Twitter Feed for polls as part of Player interaction. Participation in this aspect requires the use of a Twitter account.</p>

            <p>From Twitter, you may disclose the following either through sending us a direct message to us on the Server or by emailing us at <Email email={email.support} />:</p>

            <ul className="browser-default">
              <li><strong>Username</strong> - this is how we identify you as a Player on our Twitter Feed</li>
            </ul>

            <p>Hints may be disclosed on the Twitter Feed and further clarification will be provided to Players who disclose their Twitter username either through the tweet or through Twitter direct messages.</p>
          </section>

          <section id="privacy-email">
            <h3>Email</h3>

            <p>We do not collect email addresses as part of our information collection policy. Your email will only be used in replying to any emails you send to us.</p>
          </section>

          <section id="privacy-other-personal-information">
            <h3>Other Personal Information</h3>

            <p>Besides the Personal Information enntailed above in regards to Discord, Reddit, Twitter, and email, please do not disclose to us other personal or sensitive information.</p>
          </section>

          <section id="privacy-cookies">
            <h2>Cookies Policy</h2>

            <p>This Cookies policy explains what Cookies are and how we use them. You should read this policy so you can understand what type of cookies we use, the information we collect using Cookies, and how that information is used.</p>

            <p><strong>Cookies</strong> mean small files that are placed on your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</p>

            <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from Cookies. For further information on how we use, store and keep your personal data secure, see our <LinkIn page={1} />.</p>

            <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies we use.</p>
          </section>

          <section id="privacy-cookies-use">
            <h3>Cookies We Use</h3>

            <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser.</p>

            <p>We use both session and persistent Cookies for the purposes set out below:</p>

            <ul className="browser-default">
              <li>
                <strong>Necessary / Essential Cookies</strong>

                <ul>
                  <li>Type: Session Cookies</li>

                  <li>Administered by: Us</li>

                  <li>Purpose: These Cookies are essential to provide you with services available through the Site and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.</li>
                </ul>
              </li>

              <li>
                <strong>Functionality Cookies</strong>
                
                <ul>
                  <li>Type: Persistent Cookies</li>
                  
                  <li>Administered by: Us</li>

                  <li>Purpose: These Cookies allow us to remember choices you make when you use the Site, such as remembering your login details or language preference. The purpose of these Cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website.</li>
                </ul>
              </li>
            </ul>
          </section>

          <section id="privacy-cookies-choices">
            <h3>Your Choices Regarding Cookies</h3>

            <p>If you prefer to avoid the use of Cookies on the Site, first you must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>

            <p>If you do not accept our Cookies, you may experience some inconvenience in your use of the Site and some features may not function properly.</p>

            <p>If you'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</p>

            <ul className="browser-default">
              <li>For the Chrome web browser, please visit this page from Google: <LinkOut target="_blank" link="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</LinkOut></li>

              <li>For the Internet Explorer web browser, please visit this page from Microsoft: <LinkOut target="_blank" link="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</LinkOut></li>

              <li>For the Firefox web browser, please visit this page from Mozilla: <LinkOut target="_blank" link="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</LinkOut></li>

              <li>For the Safari web browser, please visit this page from Apple: <LinkOut target="_blank" link="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</LinkOut></li>
            </ul>

            <p>For any other web browser, please visit your web browser's official web pages.</p>
          </section>

          <section id="privacy-cookies-more">
            <h3>More Information about Cookies</h3>

            <p>You can learn more about Cookies at the following third-party websites:</p>

            <ul className="browser-default">
              <li>Network Advertising Initiative: <LinkOut target="_blank" link="http://www.networkadvertising.org/">http://www.networkadvertising.org/</LinkOut></li>
            </ul>

            <p>If you have any questions about this Cookies Policy, you can contact us at <Email email={email.support} />.</p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;