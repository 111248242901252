import React, { useState, useEffect } from "react";

import API from "../utils/API";
import { theme, archived } from "../utils/index";
import { datetime } from "../utils/time";
import { paths } from "../pages";

import { Container, Row, Col } from "../components/Grid";
import { Title, StatusMsg } from "../components/Msg";
import { Form, Fieldset, Select, Option, DatepickerM, TimepickerM, TextArea } from "../components/Form";
import { Switch, SwitchUTC } from "../components/Switch";
import { DiscordChatWrap, DiscordChat } from "../components/DiscordChat";
import { Btn } from "../components/Btn";

const Archiver = props => {
  //  Form data view
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [startDT, setStartDT] = useState();
  const [endDT, setEndDT] = useState();
  const [utcTime, setUtcTime] = useState(false);
  const [channelId, setChannelId] = useState();
  const [channelName, setChannelName] = useState();
  const [messages, setMessages] = useState([]);

  //  Status Messages
  const status = {
    noAccess: "You do not have the proper access level to view this page.",
    noChannels: "Could not retrieve any channels from Discord.",
    noChannel: "Select a channel to retrieve Discord Messages from.",
    selectChats: "Select a chat as the starting point and a chat as an ending point.",
    noChats: `Messages from ${channelName} will go here.`
  };

  //  Extraction functions
  const extract = {
    channels: async () => {
      const channelDb = await API.getChannels();
      let channelSort = channelDb.data
        .sort((a, b) => (a.guild > b.guild) ? 1 : -1)
        .sort((a, b) => (a.channel > b.channel) ? 1 : -1);
      setChannels(channelSort);
    },

    users: async () => {
      const userDb = await API.getUserAll();
      setUsers(userDb.data);
    },

    messages: async event => {
      event.preventDefault();
      setMessages([]);

      //  Get the query for the start datetime and end datetime
      let query = {};

      if (start) {
        let startD = document.getElementById("startDate").value;
        let startT = document.getElementById("startTime").value;

        if ((startD.length > 0) && (startT.length > 0)) {
          query.startPoint = new Date(`${startD} ${startT}`).getTime();
          setStartDT(query.startPoint);
        }
      }

      if (end) {
        let endD = document.getElementById("endDate").value;
        let endT = document.getElementById("endTime").value;

        if ((endD.length > 0) && (endT.length > 0)) {
          query.endPoint =  new Date(`${endD} ${endT}`).getTime();
          setEndDT(query.endPoint);
        }
      }

      //  Get the Discord Messages from the time range
      const chatDb = await API.extractChats(channelId, query);
      setMessages(chatDb.data);
    }
  };

  const [formLoaded, setFormLoaded] = useState(false);
  const [channels, setChannels] = useState([]);
  const [users, setUsers] = useState([]);

  //  Set up form data
  useEffect(() => {
    if (!formLoaded) {
      extract.channels();
      extract.users();
      setFormLoaded(true);
    }
  }, [extract, formLoaded]);


  //  Form data input
  const [channel, setChannel] = useState();
  const [logType, setLogType] = useState("log");
  const [context, setContext] = useState("");
  const [chats, setChats] = useState([]);
  const [locked, setLocked] = useState(false);

  const pageNum = {
    [archived[0]]: 10,
    [archived[1]]: 12,
    [archived[2]]: 14,
    [archived[3]]: 16
  };
  
  const error = {
    waiting: `Select a Discord channel, pick the type of archive log this will be, and choose where the archive begins and ends.`,
    noLogType: `Make sure that you have selected the type of archive log that this will be.`,
    noMessages: `Make sure that you have selected a starting Discord Message and an ending Discord Message.`,
    generating: `Creating a ${logType} with Discord Messages from ${channelName}
    ${(start) ? ` starting at ${datetime(startDT, utcTime, true)}` : ""} 
    ${(start && end) ? "and" : ""}
    ${(end) ? ` ending at ${datetime(endDT, utcTime, true)}` :  ""}...`,
    success: `The archived ${logType} has been successfully made and can be found in the ${paths[pageNum[logType]].title}.`,
    failed: `Something went wrong when trying to create the ${logType}.`
  };

  const [errorMsg, setErrorMsg] = useState(error.waiting);

  //  Setting functions
  const setup = {
    channel: event => {
      for (let i = 0; i < channels.length; i ++) {
        if (channels[i]._id === event.target.value) {
          setChannel(channels[i]._id);
          setChannelId(channels[i].channelId);
          setChannelName(`#${channels[i].channel}`);
          break;
        }
      }
    },

    chat: event => {
      const checked = document.querySelectorAll("[name=discordChats]:checked");

      // Checks that 2 Discord Messages have been selected
      if (checked.length > 2) { event.target.checked = false; }
      else if (checked.length === 2) {
        let logging = false;
        let msgLog = [];

        //  Sets up the log for the Raw Log
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].chatId === checked[0].value) logging = true;

          if (logging) msgLog.push(messages[i]);

          if (messages[i].chatId === checked[1].value) {
            logging = false;
            break;
          }
        }

        setChats(msgLog);
      } else {
        setChats([]);
      }
    },

    user: (oid, display) => {
      for (let i = 0; i < users.length; i++) {
        if (oid === users[i]._id) {
          if (users[i].bot) {
            if (users[i].related.length > 0) {
              return users[i].related[display].bot_name;
            } else if (users[i].username) {
              return users[i].display;
            }
          } else {
            const uname = users[i].username.substring(0, users[i].username.lastIndexOf("#"));

            const userStr = (users[i].nicknames[display] === uname)
            ? uname
            : `${users[i].nicknames[display]} (${uname})`;
  
            return userStr;
          }
        }
      }

      return "Unknown";
    },

    log: async event => {
      event.preventDefault();

      if (logType === null) {
        setErrorMsg(error.noLogType);
      } else if (chats.length === 0) {
        setErrorMsg(error.noMessages);
      } else {
        setErrorMsg(error.generating);

        let log = {
          logType: logType,
          chats: chats,
          channel: chats[0].channel,
          timestamp: chats[0].timestamp,
          context: context,
          locked: locked
        };
  
        API.createLog(log)
          .then(() => {
            setErrorMsg(error.success);
          })
          .catch(err => {
            setErrorMsg(error.failed);
            console.log(err);
          });
      }
    }
  };

  return (
    <Container>
    {(props.rank === 3)
    ? <>
      <Row>
        <Col>
          <Title page={props.page} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form>
            <Fieldset name="Archived Chat Information">
              <Row>
                <Col>
                  <p>Generate an archived chat with this form.<br />Please note that the more Messages a channel has, the longer it will take for the Messages you requested to appear.</p>
                </Col>
              </Row>

              <Row>
                <Col size="s12 m8">
                  <Fieldset name="Discord Channel">
                  {(channels.length > 0)
                  ? <Select 
                      name="channel"
                      label="Discord Text Channels"
                      defaultValue="none"
                      handleInputChange={e => setup.channel(e)}
                    >
                      <Option key="0" value="none" disabled={true}>
                        Select a Channel
                      </Option>

                      {channels.map(discord => <Option
                        key={discord._id}
                        value={discord._id}
                        icon={`https://cdn.discordapp.com/icons/${discord.guildId}/${discord.guildIcon}`}
                      >
                        #{discord.channel}: {discord.guild}
                      </Option>)}
                    </Select>
                  : <StatusMsg>{status.noChannels}</StatusMsg>
                  }
                  </Fieldset>
                </Col>

                <Col size="s12 m4">
                  <Fieldset name="Type of Archive">
                    <Select 
                      name="logType"
                      label="Type of Archive"
                      defaultValue="log"
                      handleInputChange={e => setLogType(e.target.value)}>
                      
                      {archived.map((typeL, index) => <Option
                        key={index}
                        value={typeL}
                      >{typeL.charAt(0).toUpperCase() + typeL.substring(1)}</Option>)}
                    </Select>
                  </Fieldset>
                </Col>
              </Row>
              
              <Row>
                <Col size="s12 m6">
                  <Fieldset name="Start Datetime">
                    <Row>
                      <Col>
                        <Switch id="startEnable" handleInputChange={e => setStart(e.target.checked)} />
                      </Col>

                      <Col size="s12 l8">
                        <DatepickerM id="startDate" disabled={!start} required={true}>
                          Start Date
                        </DatepickerM>
                      </Col>

                      <Col size="s12 l4">
                        <TimepickerM 
                        name="startTime" id="startTime" disabled={!start} required={true}>
                          Start Time
                        </TimepickerM>
                      </Col>
                    </Row>
                  </Fieldset>
                </Col>

                <Col size="s12 m6">
                  <Fieldset name="End Datetime">
                    <Row>
                      <Col>
                        <Switch id="endEnable" handleInputChange={e => setEnd(e.target.checked)} />
                      </Col>

                      <Col size="s12 l8">
                        <DatepickerM id="endDate" disabled={!end} required={true}>
                          End Date
                        </DatepickerM>
                      </Col>

                      <Col size="s12 l4">
                        <TimepickerM id="endTime" disabled={!end} required={true}>
                          End Time
                        </TimepickerM>
                      </Col>
                    </Row>
                  </Fieldset>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Fieldset name="Context">
                    <TextArea id="context" handleInputChange={e => setContext(e.target.value)}>
                      Archived Chat Context
                    </TextArea>
                  </Fieldset>
                </Col>
              </Row>

              <Row>
                <Col size="s12 l5">
                  <Btn handleClickEvent={extract.messages}>
                    Retrieve Discord Messages
                  </Btn>
                </Col>

                <Col size="s12 m6 l4">
                  <Switch name="lockLog" handleInputChange={e => setLocked(e.target.checked)}>
                    Lock {logType} from public view?
                  </Switch>
                </Col>
                
                <Col size="s12 m6 l3">
                  <SwitchUTC handleInputChange={e => 
                    setUtcTime(e.target.checked)} />
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <Fieldset name="Discord Messages">
                  {(channel)
                  ? (messages.length > 0)
                    ? <DiscordChatWrap id="discord-chats"
                        channel={channelName}
                        colors={`discord-header ${theme.tint}`}>
                        {messages.map(chat => <DiscordChat
                          key={chat.chatId}
                          chatId={chat.chatId}
                          name={setup.user(chat.user, chat.display)}
                          message={chat.message}
                          time={chat.timestamp}
                          utc={utcTime}
                          handleClick={e => setup.chat(e)} />
                        )}
                      </DiscordChatWrap>
                    : <StatusMsg>{status.noChats}</StatusMsg>
                  : <StatusMsg>{(channelName) 
                    ? status.loadingChats : status.noChannel}</StatusMsg>
                  }
                  </Fieldset>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Btn color={theme.success} handleClickEvent={setup.log}>
                    Create Archive Log
                  </Btn>
                </Col>
              </Row>

              <Row>
                <Col>
                  <StatusMsg>{errorMsg}</StatusMsg>
                </Col>
              </Row>
            </Fieldset>
          </Form>
        </Col>
      </Row>
    </>
    : <Row>
        <Col>
          <StatusMsg>{status.noAccess}</StatusMsg>
        </Col>
      </Row>}
    </Container>
  );
};

export default Archiver;