//  Website color themes
export const theme = {
  base: "blue-grey darken-3",
  tint: "blue-grey darken-2",
  shade: "blue-grey darken-4",
  accent: "amber lighten-5",
  
  success: "green lighten-1",
  warning: "yellow lighten-3",
  alert: "red lighten-2",

  text: "amber-text text-lighten-5",
  textAlt: "blue-grey-text text-darken-3",
  link: "blue-grey-text text-darken-1",
  linkAlt: "blue-text text-darken-1",

  division: [
    "blue darken-3 white-text",
    "black white-text",
    "white black-text",
    "red darken-4 white-text",
    "yellow black-text",
    "grey lighten-3 black-text"
  ]
};

//  Common terminology
export const terms = {
  company: "28 Moon House",
  website: "28MoonHouse.com",
  url: "https://www.28moonhouse.com/",
  bot: "Tiger Head",
  division: [
    "Dragon Division",
    "Tortoise Division",
    "Tiger Division",
    "Bird Division",
    "Da Boss",
    "Intern"
  ],
  former: "BenitoTek"
};

//  Staff departments
export const department = {
  executive: "Executive",
  manager: "Manager",
  security: "Security Officer",
  hr: "Human Resources Officer",
  maintenance: "Maintenance",
  intern: "Intern",
  ghosted: "Ghosted",
  info: "Information Desk"
};

//  Locations
export const places = {
  lobby: "Lobby",
  floor1: "Floor 1",
  floor2: "Floor 2",
  floor3: "Floor 3",
  floor4: "Floor 4",
  floor5: "Floor 5",
  basement: "Basement",
  courtyard: "Courtyard",
  parking: "Parking Garage",
  infirmary: "Infirmary",
  office: "Main Office"
};

//  Common emails
export const email = {
  support: "support@28moonhouse.com",
  emperor: "ryan.huang@28moonhouse.com",
  dragon: "drake.bloom@28moonhouse.com",
  tortoise: "heidi.norman@28moonhouse.com",
  tiger: "william.west@28moonhouse.com",
  bird: "camilla.robinson@28moonhouse.com",
  clinic: "gregorio.shaw@28moonhouse.com",
  maintenance: "lawrence.gee@28moonhouse.com",
  contractor: "deborah.gee@28moonhouse.com"
};

//  Archive log types
export const archived = [
  "log",
  "memo",
  "recording",
  "transmission"
];

//  Social media links
export const social = {
  discord: "https://discord.gg/VUYEfRa",
  reddit: "https://www.reddit.com/r/28MoonHouse/",
  twitter: "https://twitter.com/28MoonHouse",
  github: "https://github.com/28MoonHouse",
  telegram: "https://t.me/MoonHouse28"
};

//  Tiger Head OS commands
export const bot = {
  version: ">th version",
  division: ">th onboarding",
  info: ">th info"
};