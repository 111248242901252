// Functions for formatting numbers. dates, and times
const format = {
  /**
   * Adds a "0" at the beginning of a number if it's less than 10
   * @param {integer} num - A number to be formatted 
   * @return {string} - The formatted number
   */
  num: (num) => {
    let numStr = (num < 10) ? "0" + num : num;
    return numStr;
  },

  /**
   * Converts a Date into a date string for the month, day, and year
   * @param {Date} datetime - Date object to be formatted
   * @param {boolean} utc - Whether the time is UTC or local time
   * @return {string} - The formatted string as "MM/DD/YYYY"
   */
  date: (datetime, utc) => {
    let timeStr = (utc)
      ? `${datetime.getUTCFullYear()}-${format.num(datetime.getUTCMonth() + 1)}-${format.num(datetime.getUTCDate())}`
      : `${datetime.getFullYear()}-${format.num(datetime.getMonth() + 1)}-${format.num(datetime.getDate())}`;

    return timeStr;
  },

  /**
   * Converts a Date into a date string for the hour and minutes
   * @param {Date} datetime - Date object to be formatted
   * @param {boolean} utc - Whether the time is UTC or local time
   * @return {string} - The formatted string as "HH:mm"
   */
  time: (datetime, utc) => {
    let timeStr =  (utc) 
    ? `${format.num(datetime.getUTCHours())}:${format.num(datetime.getUTCMinutes())}`
    : `${format.num(datetime.getHours())}:${format.num(datetime.getMinutes())}`;

    return timeStr;
  },

  /**
   * Formats and return a timestring
   * @param {string} datetime - Datetime string to be converted to a time string
   * @param {boolean} utc - Whether the timestring is UTC or local
   * @param {boolean} min - Whether to show seconds in the time
   * @return {string} - The time string as "MM/DD/YYYY HH:mm" or "MM/DD/YYYY HH:mm:ss"
   */
  datetime: (datetime, utc, min = false) => {
    let time = new Date(datetime);

    let timeStr = `${format.date(time, utc)} ${format.time(time, utc)}`;

    if (!min) timeStr += (utc) 
      ? `:${format.num(time.getUTCSeconds())}`
      : `:${format.num(time.getSeconds())}`;
    
    return timeStr;
  },

  /**
   * Formats and returns a timestring that shows a range between two datetimes
   * @param {string} startTime - Start datetime to be converted
   * @param {string} endTime - End datetime to be converted
   * @param {boolean} utc - Whether the time string should be in UTC or local time
   */
  range: (startTime, endTime, utc) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    let timeStr = (format.date(start, utc) && format.date(end, utc))
    ? `${format.datetime(start, utc, true)}-${format.time(end, utc)}`
    : `${format.datetime(start, utc, true)}-${format.datetime(end, utc, true)}`;

    return timeStr;
  }
};

module.exports = format;