import React from "react";

import { email, terms, theme, social } from "../utils";

import { Container, Row, Col } from "../components/Grid";
import { Title } from "../components/Msg";
import { LinkIn, LinkOut, Email } from "../components/Btn";
import { List, ListHeader, ListItem } from "../components/List";

const ApplyToday = props => {
  return (
    <Container>
      <Row>
        <Col>
          <Title page={props.page} />
        </Col>
      </Row>
      <Row>
        <Col size="s12">
          <p>Thank you for your interest in {terms.company}!</p>

          <p>We are always looking for new people interested in joining our family of employees. If you are interested in applying, follow the steps to <strong>apply to {terms.company}</strong> and we will process your application as soon as possible to join our pool of talented candidates.</p>
        </Col>

        <Col size="s12">
          <List>
            <ListHeader>
              <h2 className={`${theme.text} center-align`}>Apply to {terms.company}</h2>
            </ListHeader>
            
            <li>
              <ol>
                <ListItem>
                  Sign up for <LinkOut target="_blank" link="https://discordapp.com/" color={theme.linkAlt}>Discord</LinkOut>.
                </ListItem>

                <ListItem>
                  Bring your application to our <LinkOut link={social.discord} color={theme.linkAlt}>facility</LinkOut> <strong>*</strong>.
                </ListItem>

                <ListItem>
                  Log on to the <LinkIn page={4} color={theme.linkAlt} /> to review your application.
                </ListItem>

                <ListItem>
                  Complete the <LinkIn page={18} color={theme.linkAlt} name="Onboarding">Onboarding Assessment</LinkIn> to complete your application.
                </ListItem>
              </ol>
            </li>
          </List>
        </Col>
      </Row>

      <Row>
        <Col size="s12">
          <p><strong>*</strong> If you find yourself unable to turn in your application, email <Email email={email.support} /> so that we may assist your application process. We understand that there may be some difficulty in reaching the facility or getting past security. However, if you are found to be on the company blacklist, your application process may be discontinued.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ApplyToday;