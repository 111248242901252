import React, { useEffect } from "react";
import { A } from "hookrouter";
import { Dropdown, Sidenav } from "materialize-css";

import { paths } from "../../pages"
import { theme, terms } from "../../utils";
import "./style.css";

import { Icon } from "../Icon";
import { NavItem } from "../List";
import { LinkInC } from "../Btn";

const Header = props => {
  useEffect(() => {
    Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {});
    Sidenav.init(document.querySelectorAll(".sidenav"), {});
  });

  return (
    <header>
      <nav className="nav-extended">
        <div className={`nav-wrapper ${theme.base}`}>
          <LinkInC page={0} className="brand-logo">{terms.company}</LinkInC>

          <A href="#" data-target="nav-mobile" className={`sidenav-trigger ${theme.text}`}>
            <Icon>menu</Icon>
          </A>

          <ul className={`right hide-on-med-and-down`}>
          {(props.rank > 0)
          ? <>
          <li>
            <A className={`dropdown-trigger ${theme.text}`} href="#!" data-target="dropdown-archive">
              Archives

              <Icon size="right">arrow_drop_down</Icon>
            </A>
          </li>

          <li>
            <A className={`dropdown-trigger ${theme.text}`} href="#!" data-target="dropdown-user">
              {props.user}
              
              <Icon size="right">arrow_drop_down</Icon>
            </A>
          </li>
          </>
          : <LinkInC page={4} />}
          </ul>
        </div>
      </nav>

      {/* Archives */}
      <ul id="dropdown-archive" className={`dropdown-content ${theme.tint} ${theme.text}`}>
        <li>
          <LinkInC page={9} />
        </li>

        <li>
          <LinkInC page={10} />
        </li>

        <li>
          <LinkInC page={12} />
        </li>

      {(props.rank > 1) && <>
        <li>
          <LinkInC page={14} />
        </li>

        <li>
          <LinkInC page={16} />
        </li>

        {(props.rank > 2) &&
        <li>
          <LinkInC page={8} />
        </li>
        }
      </>}
      </ul>

      {/* User Profile and Logout */}
      <ul id="dropdown-user" className={`dropdown-content ${theme.tint} ${theme.text}`}>
        <li>
          <LinkInC page={3} />
        </li>

        <li>
          <LinkInC page={5}>
            {paths[5].title}
            <Icon size="right">exit_to_app</Icon>
          </LinkInC>
        </li>
      </ul>

      {/* Mobile Navbar */}
      <ul className={`sidenav ${theme.tint} ${theme.text}`} id="nav-mobile">
        <li>
          <p className={`mobile-title ${theme.base} ${theme.text}`}>{terms.company}</p>
        </li>

        {(props.rank > 0)
        ? <>
        <NavItem page={9} icon="list" />

        <NavItem page={10} icon="rss_feed" />

        <NavItem page={12} icon="message" />

        {(props.rank > 1) && <>
        <NavItem page={14} icon="playlist_play" />

        <NavItem page={16} icon="settings_remote" />
        </>}

        {(props.rank > 2) && <NavItem page={8} icon="edit" />}

        <NavItem page={3} icon="person">
          {props.user}
        </NavItem>
        
        <NavItem page={5} icon="exit_to_app" /></>
        : <NavItem page={4} icon="input" />}
      </ul>
    </header>
  );
};

export default Header;