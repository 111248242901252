import React from "react";

import { theme } from "../../utils";
import "./style.css";

import { Col } from "../Grid";

//  Materialize Switch
export const Switch = props => {
  return (
    <Col size={props.size || "s12"} styles="switch">
      <p>{props.children}</p>

      <label>
        {props.offLabel || "No"}

        <input type="checkbox" id={props.id} disabled={props.disabled} onChange={props.handleInputChange} />

        <span className={`lever ${props.color || theme.tint}`}></span>

        {props.onLabel || "Yes"}
      </label>
    </Col>
  );
};

//  Switch for UTC or local time
export const SwitchUTC = props => {
  return (
    <Switch name="utcTime"
      offLabel="Local Time"
      onLabel="UTC"
      handleInputChange={props.handleInputChange}
    >
      {props.children || "Select Time Format"}
    </Switch>
  );
};