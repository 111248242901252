import React, { useState, useEffect } from "react";
import { Btn } from "../../components/Btn";
import { RadioBtn } from "../../components/Form";

import { Container, Row, Col } from "../../components/Grid";
import { Title } from "../../components/Msg";

export const Lunhui = props => {
  // Lunhui Puzzle Questions
  const [questions, setQuestions] = useState([
    {
      question: "Who bought out 28 Moon House?",
      choices: [{
        text: "West Mountain Clinic",
        response: "A close associate though the staff led by Dr. Regina Belmont prefer working on biomedical research than the extradimensional work done at 28 Moon House."
      }, {
        text: "Macaque Enterprises",
        response: "A good guess, but Eugene Sun was more interested in William West's bleeding edge reality work than the company."
      }, {
        text: "Regent Courts LLP",
        response: "While Henry Lau did finalize the buyout, he is more interested in handling legal matters than research and development matters."
      }, {
        text: "Jade Imperium Holdings",
        response: "This"
      }]
    }, {
      question: "How many updates did Tiger Head OS receive?",
      choices: [{
        text: "4",
        response: "Once the Shennong Wall was breached, Tiger Head OS was able to obtain a human vessel."
      }, {
        text: "5",
        response: "Orchid Protocol allowed Louis Ying to hack into 28 Moon House."
      }, {
        text: "9",
        response: "game"
      }, {
        text: "10",
        response: "There is no tenth update. Tiger Head OS has been freed of constraints to control the facility as it pleases."
      }]
    }, {
      question: "Why did the Mountain of Knives explode?",
      choices: [{
        text: "Scheduled demolition for prolonged suffering",
        response: "The explosion was not scheduled."
      }, {
        text: "Yanluo Wang losing at minesweeper",
        response: "is"
      }, {
        text: "Randomly timed explosions for both physical and psychological torture",
        response: "The timing is not random."
      }, {
        text: "An accident on the imps' behalf",
        response: "The explosion was not an accident."
      }]
    }, {
      question: "What is a cure for Space AIDS?",
      choices: [{
        text: "Peaches of Immortality",
        response: "finished"
      }, {
        text: "Mooncake",
        response: "Look inside the filling of Luna Chang's mooncakes, not the cake itself."
      }, {
        text: "Osmanthus Wine",
        response: "It's not a cure but it's a good drink to serve during a reunion."
      }, {
        text: "No cure",
        response: "There is a cure! Don't give up so easily!"
      }]
    }, {
      question: "What happened to Winston Li?",
      choices: [{
        text: "Died from health complications",
        response: "Complications from vesselization but that is only half the truth."
      }, {
        text: "Euthanized by Luna Chang",
        response: "Despite being his nurse, Luna Chang only watched him die from his own condition."
      }, {
        text: "Murdered by William West",
        response: "By passing on the Five Deities to new vessels, Winston Li was finally able to die."
      }, {
        text: "Returned to being Luxing",
        response: "Waiting"
      }]
    }, {
      question: "Where is Peter Saelu's office located?",
      choices: [{
        text: "Kuala Lumpur",
        response: "Steven Du oversees the entrance to the Eighth Court next to a bustling market."
      }, {
        text: "Bangkok",
        response: "for"
      }, {
        text: "Singapore",
        response: "Somewhere in a basement is the office of Thomas Dong who oversees the entrance to the Seventh Court."
      }, {
        text: "Victoria",
        response: "Despite being prompted to move to Vancouver for more clients, Bernard Bi has kept the entrance to the Sixth Court at its current location since 1911."
      }]
    }, {
      question: "Why have Brendan Yu and Dr. Regina Belmont remained apart?",
      choices: [{
        text: "Her refusal to admit she did anything wrong",
        response: "In 1949, Brendan Yu wrote a letter of reconciliation to Dr.Rafaela Serra placing the blame for their divorce entirely on her."
      }, {
        text: "His unwarranted letters",
        response: "one"
      }, {
        text: "Their current representatives",
        response: "Despite the attempts of Raymond Yuan and Miriam Yuan to get him to see reason, Brendan Yu continued to send Dr. Regina Belmont letters containing lewd content."
      }, {
        text: "Deceptions from third parties",
        response: "For many years, Jiubaoyu sifted through the West Mountain Clinic's mail to ensure that only letters of pertinent business were sent to Dr. Regina Belmont."
      }]
    }, {
      question: "What happened to Dr. Shaw?",
      choices: [{
        text: "Trapped in Diyu",
        response: "Only temporarily for he has returned to Earth."
      }, {
        text: "Reincarnated to a new life",
        response: "It's not a true reincarnation since he took the wrong door."
      }, {
        text: "Returned to his office",
        response: "final"
      }, {
        text: "Food for imps",
        response: "The imps would rather mine his medical knowledge to learn how to torture better than eat him."
      }]
    }, {
      question: "Where is Deborah Gee?",
      choices: [{
        text: "Wandering Diyu",
        response: "Only temporarily as she has accomplished what needed to be done down there."
      }, {
        text: "Back home with her parents",
        response: "After living with her parents for 20 years, she is ready to move out and see the world."
      }, {
        text: "Crashing at Camilla Robinson's place",
        response: "While Deborah Gee does enjoy hanging out with her bestie, there are other places she must be."
      }, {
        text: "Hiding in the basement",
        response: "call"
      }]
    }, {
      question: "Who are Raymond Yuan and Miriam Yuan?",
      choices: [{
        text: "Fuxi and Nuwa",
        response: "Named"
      }, {
        text: "Xiu Xian and Bai Suzhen",
        response: "After reuniting, the scholar and the White Snake lived happily with their son for the rest of their lives."
      }, {
        text: "Niulang and Zhinu",
        response: "Despite their attempt during the Qixi Festival, Niulang and Zhinu were unable to stop Brendan Yu from asking Dr. Regina Belmont about the contents of his letters to her."
      }, {
        text: "Yuanshi Tianzun and Jiutian Xuannu",
        response: "Unlike some of their other peers, Yuanshi Tianzun and Jiutian Xuannu are more focused on spiritual matters."
      }]
    }, {
      question: "Why did Howie run away from West Mountain Clinic?",
      choices: [{
        text: "The West Mountain Clinic staff used him as their go-to experimental subject",
        response: "As Howie is Luna Chang's beloved companion, he sometimes assists her with her experiments."
      }, {
        text: "He wandered off the grounds",
        response: "In his privileged position, he is allowed to wander off the grounds, causing Luna Chang to have to search for him."
      }, {
        text: "He was mad at Luna Chang",
        response: "Knowing that he can be a willful rabbit, Luna Chang often spoils Howie when she finds him after he runs away."
      }, {
        text: "Dr. Regina Belmont injected him with Space AIDS for a petty scheme",
        response: "the"
      }]
    }, {
      question: "Who is Louis Ying?",
      choices: [{
        text: "Erlang Shen",
        response: "Ernest Yu spends most of his time overseeing the engineering in Tian when he is not obligated to watch over Gonggong."
      }, {
        text: "Liu Bang",
        response: "The first emperor of the Han Dynasty is well-remembered for taking over from Qin Shi Huangdi."
      }, {
        text: "Luxing",
        response: "epilogue"
      }, {
        text: "Li Bing",
        response: "The great vanquisher of the River God continues to be well-renowned for Dujiangyan and continues to be employed as an engineer at Jade Imperium Holdings."
      }]
    }
  ]);

  // Node Names
  const nodes = [
    "Rat",
    "Ox",
    "Tiger",
    "Rabbit",
    "Dragon",
    "Snake",
    "Horse",
    "Goat",
    "Monkey",
    "Rooster",
    "Dog",
    "Pig"
  ];

  // States
  const [started, setStarted]  = useState(false);
  const [complete, setComplete] = useState(false);
  const [curr, setCurr] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [answer, setAnswer] = useState("");

  // Functions
  const randomize = (arr, same) => {
    let temp = arr[same];
    let index = same;

    do index = Math.floor(Math.random() * arr.length)
    while (index === same);

    arr[same] = arr[index];
    arr[index] = temp;
  };

  const move = index => {
    let choices = document.getElementsByName(`question${index}`);
    for (let i = 0; i < choices.length; i++) choices[i].checked = false;

    setCurr(index);
    setAnswered(false);
  };

  useEffect(() => {
    let arr = questions;
    for (let i = 0; i < arr.length; i++) {
      // Randomizes the order of the choices
      for (let j = 0; j < arr[i].choices.length; j++) {
        randomize(arr[i].choices, j);
      }

      // Randomizes the order of the questions
      randomize(arr, i);
    }

    setQuestions(arr);
  }, [complete, questions]);

  return (<Container styles="center-align">
    {(started)
    ? <Row>
      <Col size="s12 m6 offset-m3">
        <h2>{nodes[curr]}</h2>

        <p>{questions[curr].question}</p>

        <Row>
          {answered
          ? <Col>
            <p>{answer}</p>
          </Col>
          : <Col>
            {questions[curr].choices.map((option, index) => <RadioBtn
              key={index}
              list={true}
              name={`question${curr + 1}`}
              value={option.text}
              handleInputChange={() => {
                setAnswered(true);
                setAnswer(questions[curr].choices[index].response);
              }}>
              {(option.text)}
            </RadioBtn>)}
          </Col>}
        </Row>

        <Row>
          <Col>
            {answered ? ((curr + 1) < questions.length)
              ? <Btn handleClickEvent={() => move(curr + 1)}>Next</Btn>
              : <Btn handleClickEvent={() => {
                setComplete(true);
                setStarted(false);
                setCurr(0);
                setAnswered(false);
              }}>Finish</Btn>
            : null}
          </Col>
        </Row>
      </Col>
    </Row>
    : <Row>
      {(complete)
      ? <Col size="s12 m6 offset-m3">
        <h2>Path Completed</h2>

        <p>Did you find the answers you were looking for? If not, you can always begin again.</p>

        <Btn handleClickEvent={() => {
          setComplete(false);
        }}>Restart</Btn>
      </Col>
      : <Col size="s12 m6 offset-m3">
        <Title page={props.page} />

        <p>Follow the path to the end.</p>

        <Row>
          <Col>
            <Btn handleClickEvent={() => setStarted(true)}>Begin</Btn>
          </Col>
        </Row>
      </Col>}
    </Row>}
  </Container>)
};
