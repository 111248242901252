import React, { useEffect } from "react";
import { Carousel } from "materialize-css";

import { theme } from "../../utils";
import "./style.css";

//  Materialize Carousel
export const CarouselM = props => {
  useEffect(() => {
    Carousel.init(document.querySelectorAll(".carousel"), {
      fullWidth: props.fullWidth,
      indicators: props.indicators,
      duration: props.duration
    });
  }, [props]);


  return (
    <div className={`carousel ${props.slider ? "carousel-slider" : ""} ${props.styles || ""}`}>
      {props.children}
    </div>
  );
};

//  Materialize Carousel Item
export const CarouselItem = props => {
  return (
    <div className={`carousel-item ${props.color || theme.base} ${props.text || theme.text} ${props.styles || ""}`} href={`#${props.link}`} style={{
      backgroundImage: (props.image) ? `url(${props.image})` : "none"
    }}>
      {props.children}
    </div>
  );
};

//  Materialize Fixed Carousel Item
export const CarouselFixed = props => {
  return (
    <div className={`carousel-fixed-item ${props.styles || ""}`}>
      {props.children}
    </div>
  );
};